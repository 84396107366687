import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { DeliveryDate } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class DeliveryApiService {

  private readonly apiUrl = environment.apiUrl;
  private daDataUrl = environment.daDataUrl;

  constructor(
    private http: HttpClient,
  ) {}

  getAddressFromName(query: string, count: number): any {
    return this.http.post(this.daDataUrl + '/suggest/address', { query, count });
  }

  getAddressFromCoords(lat: number, lon: number, count: number, radius: number) {
    return this.http.post(this.daDataUrl + '/geolocate/address', { lat, lon, count, radius_meters: radius });
  }

  getDeliveryCoords(): any {
    const mskPromise = this.getDeliveryCoordsMsk();
    const moPromise = this.getDeliveryCoordsMo();

    return forkJoin([mskPromise, moPromise]);
  }

  getDeliveryCoordsMsk() {
    return this.http.get(this.apiUrl + '/map/msk-coords');
  }

  getDeliveryCoordsMo() {
    return this.http.get(this.apiUrl + '/map/mo-coords');
  }

  getDeliveryDates(): Observable<DeliveryDate[]> {
    return this.http.get(this.apiUrl + '/custom/dates').pipe(
      map((items: any) => {
        return items.map((item: any) => {
          return {
            date: new Date(item.date),
            hasOrder: item.hasOrder
          };
        });
      })
    );
  }

}
