import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { LocalStorageKeysEnum, SessionStorageKeysEnum } from '@shared/enums';
import { OrderApiService, ProfileApiService } from '@shared/services';
import { Order } from '@shared/models';

declare let dataLayer: any;

@Component({
  selector: 'app-cabinet',
  templateUrl: './cabinet.component.html',
  styleUrls: ['./cabinet.component.css']
})
export class CabinetComponent implements OnInit, OnDestroy {

  public step: number;
  public order: Order;

  private ngUnsubscribe = new Subject();

  constructor(
    private titleService: Title,
    private router: Router,
    private orderApiService: OrderApiService,
    private profileApiService: ProfileApiService,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Заказ');

    this.orderApiService.getUnpaid()
      .pipe(
        filter(unpaidInfo => unpaidInfo?.thereIsUnpaidPayment),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        this.router.navigate(['/payment']);
      });

    this.order = this.orderApiService.loadOrder();

    const savedStep = parseInt(sessionStorage.getItem('step'), 10);
    this.step = savedStep || 1;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public clearMealsCache(): void {
    // eslint-disable-next-line array-callback-return
    Object.keys(sessionStorage).map((key) => {
      if (key.indexOf('meal_') === 0) {
        sessionStorage.removeItem(key);
      }
    });
  }

  public onBack(): void {
    this.switchStep(Math.max(this.step - 1, 1));
  }

  public switchStep(step): void {
    this.step = step;
    sessionStorage.setItem(SessionStorageKeysEnum.step, step);
  }

  public onDatesSelected(order: Order): void {
    this.orderApiService.saveOrder(order);
    this.switchStep(2);
  }

  public onOrderChanged(order: Order): void {
    this.orderApiService.saveOrder(order);
  }

  public onMealsSelected(order: Order): void {
    this.orderApiService.saveOrder(order);
    this.switchStep(3);
  }

  public onOrderCreated(orderCreatedData: any): void {
    const lastOrderMealsList = this.order.getMealsList();

    if (dataLayer && dataLayer.push) {
      const userGuid = localStorage.getItem(LocalStorageKeysEnum.userGuid);

      this.profileApiService.getClientOrdersCount()
        .subscribe((ordersCount) => dataLayer.push({
          event: 'order_created',
          userId: userGuid,
          OrderCount: ordersCount === 1 ? 'first' : null,
          id: orderCreatedData.orderGuid,
          number: orderCreatedData.orderNumber,
          revenue: this.order.getFullPrice(),
          products: lastOrderMealsList
        })
        );
    }

    localStorage.setItem(LocalStorageKeysEnum.lastOrderGuid, orderCreatedData.orderGuid);
    localStorage.setItem(LocalStorageKeysEnum.lastOrderNumber, orderCreatedData.orderNumber);
    localStorage.setItem(LocalStorageKeysEnum.lastOrderMealsList, JSON.stringify(lastOrderMealsList));

    sessionStorage.clear();
    this.router.navigate(['/payment'], { queryParams: { created: true } });
  }

}
