import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

import { ProfileApiService } from '@shared/services';
import { MIN_ORDER_PRICE } from '@shared/configs';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RulesComponent implements OnInit, OnDestroy {

  public isOnboarding$ = new BehaviorSubject<boolean>(false);
  public MIN_ORDER_PRICE = MIN_ORDER_PRICE;

  private ngUnsubscribe = new Subject();

  constructor(
    private titleService: Title,
    private router: Router,
    private profileApiService: ProfileApiService,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Правила');

    this.profileApiService.isProfileFilled()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((isFilled: boolean) => {
        this.isOnboarding$.next(!isFilled);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onBack(): void {
    const path = this.isOnboarding$.getValue() ? ['/welcome'] : ['/'];

    this.router.navigate(path);
  }

}
