import { Pipe, PipeTransform } from '@angular/core';
import { MealItem } from '@shared/models';

@Pipe({
  name: 'dishTransform'
})
export class DishTransformPipe implements PipeTransform {
  shouldRotate = [
    '36',
    '45',
    '50',
    '50/50',
    '70/30',
    '50/50 + крафт',
    '70/30 + крафт',
    '50 + суповая',
    '142П',
    '36П',
  ];

  shouldIncrease = [
    'суповая',
    'суповая мал',
  ];

  /* Список кодов блюд, для картинок которых не требуется трансформация (поворот или изменение масштаба) */
  exceptionList = ['6889', '6546', '3903', '3982', '5221']

  /* Блюда, фото которых нужно увеличить на 30% в рамках задачи JFPRD-2861  */
  increaseList = ['5439', '5442'];

  transform(value: MealItem): string {
    if (!value) { return ''; }

    if (this.exceptionList.includes(value.code)) {
      return '';
    }

    if (this.shouldRotate.includes(value.containerType)) {
      return 'rotate';
    }

    if (this.shouldIncrease.includes(value.containerType) || this.increaseList.includes(value.code)) {
      return 'increase';
    }

    return '';
  }
}
