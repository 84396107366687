import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { DeliveryApiService } from '@shared/services';

// eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars-ts
declare const google: any;

@Component({
  selector: 'app-address-map',
  templateUrl: './address-map.component.html',
  styleUrls: ['./address-map.component.css']
})
export class AddressMapComponent implements OnInit {

  @Input() lat: number;
  @Input() lng: number;

  @Output() closeClicked: EventEmitter<any> = new EventEmitter();
  @Output() pointSelected: EventEmitter<any> = new EventEmitter();

  public currentLat: number;
  public currentLng: number;

  public currentCenterLat: number;
  public currentCenterLng: number;

  public address: string;
  public zoom: number;

  constructor(
    private deliveryApiService: DeliveryApiService,
  ) {}

  ngOnInit() {
    if (!this.lat || !this.lng) {
      this.currentLat = 55.751244;
      this.currentLng = 37.618423;
      this.zoom = 13;
      this.onLocateClick();
    } else {
      this.currentLat = this.lat;
      this.currentLng = this.lng;
      this.zoom = 17;
    }

    this.currentCenterLat = this.currentLat;
    this.currentCenterLng = this.currentLng;
    this.onIdle();
  }

  public onCenterChange(coords: any) {
    this.currentCenterLat = coords.lat;
    this.currentCenterLng = coords.lng;
    this.currentLat = undefined;
    this.currentLng = undefined;
  }

  public onIdle() {
    this.deliveryApiService.getAddressFromCoords(this.currentCenterLat, this.currentCenterLng, 1, 50).subscribe((data: any) => {
      if (data && data.suggestions && data.suggestions.length > 0) {
        this.address = data.suggestions[0].value;
      } else {
        console.log(data);
        console.error('Suggestions are empty');
      }
    });
  }

  public isLocateBtnVisible(): boolean {
    return !!navigator.geolocation;
  }

  public onLocateClick(): void {
    navigator.geolocation.getCurrentPosition((position) => {
      this.currentLat = position.coords.latitude;
      this.currentLng = position.coords.longitude;
      this.zoom = 17;
    },
    (error: any) => {
      if (error.code === 1) {
        // eslint-disable-next-line
        alert('У данной страницы нет разрешения на получение геолокации. Проверьте, разрешена ли геолокация для данного приложения.');
      } else if (error.code === 2) {
        // eslint-disable-next-line
        alert('Данные о геолокации недоступны.');
      } else {
        console.error('Geolocation timeout');
      }
    },
    {
      maximumAge: 60000,
      timeout: 5000,
      enableHighAccuracy: true
    });
  }

  public onCloseClick(): void {
    this.closeClicked.emit();
  }

  public hereBtnText(): string {
    if (this.address) {
      return 'Я здесь';
    }
    return 'Выберите адрес';
  }

  public onHereClick(): void {
    if (this.address) {
      this.pointSelected.emit({ name: this.address, lat: this.currentCenterLat, lng: this.currentCenterLng });
    }
  }

}
