import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ProfileApiService } from '@shared/services';
import { ProfileData } from '@shared/models';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent implements OnInit, OnDestroy {

  public profile: ProfileData;
  public phoneControl = new FormControl({ value: '', disabled: true });
  public nameControl = new FormControl('', [Validators.required]);
  public profileError: string;

  public isLoading$ = new BehaviorSubject(false);
  public isSaving$ = new BehaviorSubject(false);

  private ngUnsubscribe = new Subject();

  constructor(
    private titleService: Title,
    private router: Router,
    private profileApiService: ProfileApiService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Профиль');

    this.loadData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onProfileError(error) {
    this.profileError = error;

    return throwError(error);
  }

  public onBack(): void {
    if (!this.isSaving$.getValue()) {
      this.router.navigate(['/']);
    }
  }

  public isNameValid(): boolean {
    return !!(this.profile && this.nameControl.value);
  }

  public onNameSubmit(): void {
    if (!this.isNameValid()) {
      return;
    }

    this.profileError = '';
    this.isSaving$.next(true);

    this.profileApiService.saveProfile({
      ...this.profile,
      name: this.nameControl.value
    })
      .pipe(
        finalize(() => this.isSaving$.next(false)),
        catchError((error) => this.onProfileError(error.error?.error ? error.error.error : 'Неизвестная ошибка')),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.router.navigate(['/']);
      });
  }

  private loadData(): void {
    this.isLoading$.next(true);
    this.profileApiService.getProfile()
      .pipe(
        finalize(() => this.isLoading$.next(false)),
        catchError((error) => this.onProfileError(error.error.error ? error.error.error : 'Неизвестная ошибка')),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((profile) => {
        if (profile?.name) {
          this.profile = profile;
          this.phoneControl.setValue(profile.phone);
          this.nameControl.setValue(profile.name);

          this.cdr.markForCheck();
        } else {
          this.router.navigate(['/welcome']);
        }
      });
  }

}
