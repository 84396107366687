import { Selector } from '@ngxs/store';

import { ProfileData } from '@shared/models';
import { ProfileStateModel } from './profile.model';

export class ProfileSelectors {
  @Selector([state => state.profile])
  static state(state: ProfileStateModel): ProfileStateModel {
    return state;
  }

  @Selector([ProfileSelectors.state])
  static profile(state: ProfileStateModel): ProfileData {
    return state.profile;
  }

  @Selector([ProfileSelectors.state])
  static phone(state: ProfileStateModel): string {
    return state.phone;
  }

}
