<div class="map-container">
  <div class="map-content">
    <button (click)="onCloseClick()" class="overlay-btn close-btn"><img src="/assets/images/menu-close-btn.svg" class="overlay-btn-img close-img"></button>
    <button *ngIf="isLocateBtnVisible()" (click)="onLocateClick()" class="overlay-btn locate-btn"><img src="/assets/images/location.svg" class="overlay-btn-img location-img"></button>

    <div class="address">{{address}}</div>

    <img class="map-marker" src="/assets/images/map-marker.svg" />

    <agm-map (centerChange)="onCenterChange($event)" (idle)="onIdle()" [latitude]="currentLat" [zoom]="zoom" [longitude]="currentLng" [zoomControl]="false" [clickableIcons]="false"></agm-map>

    <div class="footer">
      <button type="button" class="btn" (click)="onHereClick()" [disabled]="!address">{{hereBtnText()}}</button>
    </div>
  </div>
</div>
