import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appColorMealType]',
})
export class ColorMealTypeDirective implements AfterViewInit {
  color: string;

  @Input('appColorMealType') mealType: string;

  constructor(private elRef: ElementRef) {}

  ngAfterViewInit(): void {
    switch (this.mealType) {
      case 'Завтраки':
        this.color = '#849dd1';
        break;
      case 'Перекусы':
        this.color = '#ffb700';
        break;
      case 'Салаты':
        this.color = '#a4c18f';
        break;
      case 'Горячее':
        this.color = '#e79b6d';
        break;
      case 'Супы':
        this.color = '#88a9d9';
        break;
      case 'Десерты':
        this.color = '#b58ad1';
        break;
      case 'Напитки':
        this.color = '#fc4e4e';
        break;
      default:
        this.color = '#DAE2F3';
        break;
    }

    this.elRef.nativeElement.style.borderBottom = '2px solid' + this.color;
  }
}
