/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Routes } from '@angular/router';

import { AuthGuardService as AuthGuard, NotAuthGuardService as NotAuthGuard } from '@shared/guards';
import * as pages from './pages';

export const ROUTES: Routes = [
  {
    path: '',
    component: pages.CabinetComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'onboarding',
    component: pages.OnboardingComponent,
    canActivate: [NotAuthGuard],
  },
  {
    path: 'login',
    component: pages.LoginComponent,
    canActivate: [NotAuthGuard],
  },
  {
    path: 'menu',
    component: pages.MenuComponent,
    canActivate: [NotAuthGuard],
  },
  {
    path: 'welcome',
    component: pages.WelcomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    component: pages.ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'address',
    component: pages.AddressComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'rules',
    component: pages.RulesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'payment',
    component: pages.PaymentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '',
  }
];
