<div class="login-container">
  <div *ngIf="!isProduction" class="dev-banner">Внимание! Это тестовая версия сайта. <a href="https://custom.justfood.pro">Перейти</a> на основной сайт.</div>

  <div class="global-preloader blocking" [hidden]="!(isLoading$ | async)">
    <img src="/assets/images/preloader.gif" />
  </div>

  <ng-template #phoneForm [ngIf]="step === 1" [ngIfElse]="smsForm">
    <form class="login-form" (ngSubmit)="onPhoneSubmit()">
      <div class="auth-text">Авторизация</div>
      <div class="auth-hint">Введите номер телефона, мы вышлем на него СМС с проверочным кодом</div>
      <div class="form-group">
        <label for="phone" class="input-hint">Ваш телефон</label>
        <div class="form-input">
          <input [formControl]="phone"
            prefix="+7 "
            mask="(000) 000-00-00"
            id="phone"
            class="login-input"
            type="tel"
            name="phone"
            placeholder="+7"
            autocomplete="off"
            required
            (paste)="onPhonePaste($event)"/>
          <div class="success-sign" [hidden]="!isPhoneValid()"><img src="assets/images/success-sign.svg" /></div>
        </div>
        <div *ngIf="phoneMessage" class="form-msg">{{ phoneMessage }}</div>
      </div>
      <button type="submit" class="btn"
         [disabled]="!isPhoneValid()">Получить код</button>
      <div class="privacy-hint">Нажимая «Получить код», вы даете согласие на получение СМС и принимаете условия<br/><a target="_blank" href="/assets/documents/oferta_custom.pdf">пользовательского соглашения</a></div>
      <div class="help-btns">
        <a [routerLink]="['/onboarding']" class="btn btn-white btn-how">Как это работает</a>
        <a [routerLink]="['/menu']" class="btn btn-white btn-menu">Посмотреть меню</a>
      </div>
    </form>
  </ng-template>

  <ng-template #smsForm>
    <form class="login-form" (ngSubmit)="onPasswordSubmit()">
      <div class="auth-text">Код подтверждения</div>
      <div class="auth-hint">На номер +7{{ phone.value }} был<br />отправлен проверочный код</div>
      <div class="form-group password-container">
        <div class="code-container">
          <input [formControl]="password" (input)="onPasswordInput($event)" (paste)="onPasswordPaste($event)" mask="0000" id="password" class="password-input login-input" type="text" pattern="[0-9]*" inputmode="numeric" name="password" maxlength="4" autocomplete="one-time-code" required />
          <div class="code-input first" [ngClass]="getPasswordDigitActivness(0)">{{ getPasswordDigit(0) }}</div>
          <div class="code-input second" [ngClass]="getPasswordDigitActivness(1)">{{ getPasswordDigit(1) }}</div>
          <div class="code-input third" [ngClass]="getPasswordDigitActivness(2)">{{ getPasswordDigit(2) }}</div>
          <div class="code-input last" [ngClass]="getPasswordDigitActivness(3)">{{ getPasswordDigit(3) }}</div>
        </div>
      </div>
      <button type="submit"
        class="btn"
        [class.is-valid]="password.valid"
        [hidden]="!isPasswordValid()"
        [disabled]="isSubmitingCode">
        {{ isSubmitingCode ? 'Пожалуйста, подождите...' : 'Войти' }}
      </button>
    </form>

    <button type="button" (click)="onResend()" class="btn resend-btn" [disabled]="timeLeft > 0" [hidden]="isPasswordValid()">
      <span [hidden]="timeLeft === 0">Запросить повторно через <span class="time">{{ timeLeft }} С</span></span>
      <span [hidden]="timeLeft > 0">Запросить код еще раз</span>
    </button>

    <div *ngIf="passwordMessage" class="form-msg">{{ passwordMessage }}</div>
  </ng-template>
</div>
