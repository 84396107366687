<app-header [isOnboarding]="false" [step]="2" (back)="onBack()"></app-header>

<div class="profile-container">
  <form class="name-form" (ngSubmit)="onNameSubmit()">
    <div class="form-header">
      <div class="form-title">Профиль</div>
    </div>

    <div class="form-controls">
      <div class="global-preloader blocking" [hidden]="!(isLoading$ | async) && !(isSaving$ | async)">
        <img src="/assets/images/preloader.gif" />
      </div>

      <ng-container *ngIf="!(isLoading$ | async)">
        <div *ngIf="phoneControl.value" class="form-group">
          <label for="phone" class="input-hint">Телефон</label>
          <input id="phone" [formControl]="phoneControl" class="phone-input" type="text" name="phone" placeholder="Телефон" autocomplete="off" maxlength="25" />
        </div>

        <div *ngIf="nameControl.value" class="form-group">
          <label for="name" class="input-hint">Имя</label>
          <input id="name" [formControl]="nameControl" class="name-input" type="text" name="name" placeholder="Введите имя" autocomplete="off" maxlength="25" required />
        </div>

        <div class="error">{{profileError}}</div>
      </ng-container>
    </div>

    <div class="footer">
      <button type="submit" class="btn" [disabled]="!isNameValid() || (isLoading$ | async) || (isSaving$ | async)">Сохранить</button>
    </div>
  </form>
</div>
