import { NgModule } from '@angular/core';

import { CapitalizePipe } from './capitalize.pipe';
import { DishTransformPipe } from './dish-transform.pipe';
import { CustomNumberPipe } from './custom-number.pipe';
import { DishImagePipe } from './dish-image.pipe';

export const pipes = [
  CapitalizePipe,
  DishTransformPipe,
  CustomNumberPipe,
  DishImagePipe,
];

@NgModule({
  declarations: [
    ...pipes
  ],
  exports: [
    ...pipes
  ]
})
export class PipesModule { }
