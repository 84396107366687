import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Inject, Input, Output, Renderer2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements AfterViewInit {

  @Input() public delay = '200';
  @Input() public placement = 'top';
  @Input() public forcePosition = false;
  @Input() public isTooltipDisabled = false;
  @Input() public tooltipClass: string;
  @Input() public rootElement;
  @Input() public scrollableContainer: HTMLElement;
  @Input('appTooltip') private tooltipContent: string;

  @Output() tooltipOpening = new EventEmitter();
  @Output() tooltipClosing = new EventEmitter();

  private displayStatusSubject = new BehaviorSubject<boolean>(false);
  private tooltip: HTMLElement;
  private tooltipPointer: HTMLElement;
  private offset = 15;
  private edgeIndent = 2 * this.offset;
  private headerOffset = this.headerHeight + this.edgeIndent;

  private get containerElement(): HTMLElement {
    return this.rootElement || this.dom.body;
  }

  private get headerHeight(): number {
    return this.dom.querySelector('.header__wrapper')?.clientHeight || 0;
  }

  constructor(
    @Inject(DOCUMENT) private dom: Document,
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  @HostListener('mouseenter') onMouseEnter() {
    if (this.getTooltipActiveStatus()) { return; }

    this.displayStatusSubject.next(true);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.displayStatusSubject.next(false);
  }

  @HostListener('click') onClick() {
    if (this.getTooltipActiveStatus()) { return; }

    this.displayStatusSubject.next(true);
  }

  ngAfterViewInit(): void {
    this.displayStatusSubject
      .pipe(
        debounceTime(Number(this.delay)),
        distinctUntilChanged()
      )
      .subscribe((isDisplayed: boolean) => {
        if (isDisplayed) {
          this.show();
        } else {
          this.hide();
        }
      });
  }

  private show(): void {
    if (this.tooltip || this.isTooltipDisabled || this.tooltipContent === null) { return; }

    this.create();
    this.setPosition();

    const scrollContainerNode = this.scrollableContainer || this.dom;

    const listenerCallback = () => {
      this.displayStatusSubject.next(false);
      scrollContainerNode.removeEventListener('scroll', listenerCallback);
    };

    scrollContainerNode.addEventListener('scroll', listenerCallback);

    setTimeout(() => {
      this.renderer.addClass(this.tooltip, 'app-tooltip-show');
      this.renderer.addClass(this.dom.body, 'tooltip-is-active');
      this.tooltipOpening.emit();
    }, 0);
  }

  private hide(): void {
    if (!this.tooltip) { return; }

    this.renderer.removeClass(this.tooltip, 'app-tooltip-show');

    setTimeout(() => {
      this.renderer.removeClass(this.dom.body, 'tooltip-is-active');
      this.renderer.removeChild(this.containerElement, this.tooltip);
      this.tooltip = null;
      this.tooltipClosing.emit();
    }, 200);
  }

  private create(): void {
    this.tooltip = this.renderer.createElement('div');
    this.tooltip.innerHTML = this.tooltipContent.trim();
    this.tooltipPointer = this.renderer.createElement('div');

    this.renderer.appendChild(this.containerElement, this.tooltip);
    this.renderer.appendChild(this.tooltip, this.tooltipPointer);

    this.renderer.addClass(this.tooltip, 'app-tooltip');
    this.renderer.addClass(this.tooltip, `app-tooltip-${this.placement}`);
    this.renderer.addClass(this.tooltipPointer, 'app-tooltip-pointer');

    if (this.tooltipClass) {
      this.renderer.addClass(this.tooltip, this.tooltipClass);
    }

    this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.delay}ms`);
  }

  private setPosition(): void {
    const hostRect: DOMRect = this.el.nativeElement.getBoundingClientRect();
    const tooltipRect: DOMRect = this.tooltip.getBoundingClientRect();
    const scrollPos: number = this.rootElement
      ? this.rootElement.scrollTop || 0
      : window.pageYOffset || this.dom.documentElement.scrollTop || 0;

    const centeredHorizontalPositionOfTooltip: number = hostRect.left + (hostRect.width - tooltipRect.width) / 2;
    const centeredVerticalPositionOfTooltip: number = hostRect.top + (hostRect.height - tooltipRect.height) / 2;

    let top: number;
    let left: number;

    if (this.placement === 'top') {
      top = hostRect.top - tooltipRect.height - this.offset;
      left = this.getCheckedLeftPosition(tooltipRect, centeredHorizontalPositionOfTooltip);

      if (!this.forcePosition && top < this.headerOffset) {
        this.renderer.removeClass(this.tooltip, 'app-tooltip-top');
        this.renderer.addClass(this.tooltip, 'app-tooltip-bottom');

        top = hostRect.bottom + this.offset;
      }

      this.alignPointerByHorizont(hostRect, left);
    }

    if (this.placement === 'bottom') {
      top = hostRect.bottom + this.offset;
      left = this.getCheckedLeftPosition(tooltipRect, centeredHorizontalPositionOfTooltip);

      if (!this.forcePosition && top + tooltipRect.height > window.innerHeight - this.edgeIndent) {
        this.renderer.removeClass(this.tooltip, 'app-tooltip-bottom');
        this.renderer.addClass(this.tooltip, 'app-tooltip-top');

        top = hostRect.top - tooltipRect.height - this.offset;
      }

      this.alignPointerByHorizont(hostRect, left);
    }

    if (this.placement === 'left') {
      top = centeredVerticalPositionOfTooltip;
      left = hostRect.left - tooltipRect.width - this.offset;
    }

    if (this.placement === 'right') {
      top = centeredVerticalPositionOfTooltip;
      left = hostRect.right + this.offset;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }

  private getCheckedLeftPosition(tooltipRect: DOMRect, left: number): number {
    const innerWidth = this.rootElement ? this.rootElement.innerWidth : window.innerWidth;

    if (left < this.edgeIndent) {
      return this.edgeIndent;
    }

    if (left + tooltipRect.width > innerWidth - this.edgeIndent) {
      return innerWidth - this.edgeIndent - tooltipRect.width;
    }

    return left;
  }

  private alignPointerByHorizont(hostRect: DOMRect, tooltipLeftPosition: number): void {
    const pointerLeftPosition = hostRect.left + hostRect.width / 2 - tooltipLeftPosition;

    this.tooltipPointer.style.left = `${pointerLeftPosition}px`;
  }

  private getTooltipActiveStatus(): boolean {
    return document.querySelector('body').classList.contains('tooltip-is-active');
  }

}
