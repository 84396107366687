import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { LocalStorageKeysEnum } from '../enums';
import { AuthService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  canActivate({ queryParams }: ActivatedRouteSnapshot): boolean {
    if (this.auth.isAuthenticated()) {
      return true;
    }

    if (window.location?.search?.includes('intro')) {
      this.router.navigate(['menu'], { queryParams });
      return false;
    }

    if (localStorage.getItem(LocalStorageKeysEnum.onboardingShowed) === '1') {
      this.router.navigate(['login'], { queryParams });
    } else {
      this.router.navigate(['onboarding'], { queryParams });
    }

    return false;
  }

}
