import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { Router } from '@angular/router';
import { formatNumber } from '@angular/common';
import * as smoothscroll from 'smoothscroll-polyfill';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { throwError, Subject, Subscription, BehaviorSubject } from 'rxjs';

import { MealsApiService, RudderStackService } from '@shared/services';
import { MealItem, Cart, Order } from '@shared/models';
import { MIN_ORDER_FREE_DELIVERY, MIN_ORDER_PRICE } from '@shared/configs';
import { CommonEventsEnum } from '@shared/enums';

declare let dataLayer: any;
// eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars-ts
declare let require: any;

@Component({
  selector: 'app-meal-list',
  templateUrl: './meal-list.component.html',
  styleUrls: ['./meal-list.component.css']
})
export class MealListComponent implements OnInit, OnDestroy {

  @Input() isDemo: boolean;
  @Input() order: Order;
  @Output() orderChanged: EventEmitter<Order> = new EventEmitter();
  @Output() mealsSelected: EventEmitter<Order> = new EventEmitter();

  public minimalCartPrice = MIN_ORDER_PRICE;
  public isLoading$ = new BehaviorSubject(false);

  public selectedDate: Date;
  public selectedType: string;
  public isVeg = false;
  public isPremium = false;

  public meals: MealItem[] = null;
  public cart: Cart;
  public ignoreScroll = false;

  private mealsSubscription: Subscription;
  private ngUnsubscribe = new Subject();

  get mealTypes(): string[] {
    if (this.meals) {
      return this.meals
        .filter((element) => this.typeFilter(element))
        .map(item => item.type)
        .filter((element, index, array) => array.indexOf(element) === index)
        .sort((n1, n2) => this.getTypeOrder(n1) - this.getTypeOrder(n2));
    }
    return ['Завтраки', 'Перекусы', 'Салаты', 'Горячее', 'Супы', 'Десерты', 'Напитки'];
  }

  get visibleMeals(): MealItem[] {
    if (this.meals) {
      return this.meals.filter((element) => this.typeFilter(element))
        .sort((n1, n2) => this.getTypeOrder(n1.type) - this.getTypeOrder(n2.type));
    }
    return [];
  }

  constructor(
    private elRef: ElementRef,
    private router: Router,
    private mealsApiService: MealsApiService,
    private rudderstack: RudderStackService,
    private cdr: ChangeDetectorRef,
  ) {
    smoothscroll.polyfill();
  }

  ngOnInit(): void {
    if (this.isDemo) {
      this.selectedDate = new Date();
      this.getDemoMeals();
    } else {
      this.cart = new Cart();

      if (!this.order) {
        this.order = new Order();
      }

      if (this.order.getItems().length === 0) {
        for (const orderDate of this.order.deliveryDates) {
          this.order.addDay(orderDate.date, new Cart());
        }
      }

      this.changeDate(this.order.deliveryDates[0].date);
    }

    if (dataLayer && dataLayer.push) {
      dataLayer.push({
        event: 'vp',
        vpURL: '/foodselection'
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (!this.ignoreScroll) {
      this.setSelectedType();
    }
  }

  public resetSelectedType(): void {
    if (this.meals.length === 0 || this.mealTypes.length === 0) {
      return;
    }

    this.selectedType = this.mealTypes[0];
    document.documentElement.scrollTop = 0;
  }

  public setSelectedType(type = null): void {
    if (type) {
      this.selectedType = type;
      this.onSelectedTypeChanged();
      return;
    }

    if (this.mealTypes.length === 0) {
      return;
    }

    if (window.scrollY === 0) {
      this.selectedType = this.mealTypes[0];
      this.onSelectedTypeChanged();
      return;
    }

    if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight - 2) {
      this.selectedType = this.mealTypes[this.mealTypes.length - 1];
      this.onSelectedTypeChanged();
      return;
    }

    const meals = this.elRef.nativeElement.querySelectorAll('.meal');
    for (const meal of meals) {
      if (meal.offsetTop > window.scrollY - 5) {
        this.selectedType = meal.getAttribute('data-type');
        this.onSelectedTypeChanged();
        return;
      }
    }
  }

  public onSelectedTypeChanged(): void {
    const type = this.elRef.nativeElement.querySelector('.meal-type[data-type="' + this.selectedType + '"]');
    const typesContainer = this.elRef.nativeElement.querySelector('.meal-types');

    if (type && typesContainer) {
      if (type.offsetLeft < typesContainer.scrollLeft) {
        typesContainer.scrollTo({
          left: type.offsetLeft,
          behavior: 'smooth'
        });
      }

      if (typesContainer.scrollLeft + typesContainer.offsetWidth - 100 < type.offsetLeft) {
        typesContainer.scrollTo({
          left: type.offsetLeft - 70,
          behavior: 'smooth'
        });
      }
    }
  }

  public getDemoMeals(): void {
    this.isLoading$.next(true);

    this.mealsSubscription = this.mealsApiService.getDemoMeals()
      .pipe(
        finalize(() => this.isLoading$.next(false)),
        catchError((error: any) => {
          return this.onMealError(error.error && error.error.error ? error.error.error : 'Неизвестная ошибка');
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((meals: MealItem[]) => {
        this.meals = meals;
        this.resetSelectedType();
      });
  }

  public loadMealsByDate(): void {
    if (this.isLoading$.getValue() && this.mealsSubscription) {
      this.mealsSubscription.unsubscribe();
    }

    const savedMealsJson = sessionStorage.getItem('meal_' + this.selectedDate.getTime());
    if (savedMealsJson) {
      this.meals = JSON.parse(savedMealsJson);
      this.resetSelectedType();
    } else {
      this.isLoading$.next(true);
      this.mealsSubscription = this.mealsApiService.getMeals(this.selectedDate)
        .pipe(
          finalize(() => this.isLoading$.next(false)),
          catchError((error: any) => {
            return this.onMealError(error.error && error.error.error ? error.error.error : 'Неизвестная ошибка');
          }),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((meals: MealItem[]) => {
          if (meals?.length > 0) {
            sessionStorage.setItem('meal_' + this.selectedDate.getTime(), JSON.stringify(meals));
          }

          this.meals = meals;
          this.resetSelectedType();
        });
    }
  }

  public onMealError(error) {
    return throwError(error);
  }

  public onTypeClick(type: string): void {
    const meal = this.elRef.nativeElement.querySelector('.meal[data-type="' + type + '"]');
    if (meal) {
      this.ignoreScroll = true;
      window.scrollTo({
        top: meal.offsetTop - 100 - Math.random(),
        behavior: 'smooth'
      });
      setTimeout(() => {
        this.ignoreScroll = false;
        this.setSelectedType(type);
        this.cdr.markForCheck();
      }, 700);
    }
  }

  public getTypeOrder(type: string): number {
    switch (type) {
      case 'Завтраки': return 1;
      case 'Перекусы': return 2;
      case 'Салаты': return 3;
      case 'Горячее': return 4;
      case 'Супы': return 5;
      case 'Десерты': return 6;
      case 'Напитки': return 7;
      default: return 999;
    }
  }

  public onVegClick(): void {
    if (!this.isLoading$.getValue()) {
      this.isVeg = !this.isVeg;
      this.resetSelectedType();
    }
  }

  public onPremiumClick() {
    if (!this.isLoading$.getValue()) {
      this.isPremium = !this.isPremium;
      this.resetSelectedType();
    }
  }

  public typeFilter(element): boolean {
    if (!this.isVeg && !this.isPremium) {
      return true;
    }

    if (this.isVeg && this.isPremium) {
      return element.veg && element.premium;
    }

    if (this.isVeg) {
      return element.veg;
    }

    if (this.isPremium) {
      return element.premium;
    }

    return false;
  }

  public itemsInCart(meal: MealItem): number {
    return this.cart ? this.cart.getItemCount(meal) : 0;
  }

  public onPriceClick() {
    if (this.isDemo) {
      this.router.navigate(['/login']);
    }
  }

  public onAddItemClick(meal: MealItem) {
    this.cart.addItem(meal);

    this.orderChanged.emit(this.order);
  }

  public onRemoveItemClick(meal: MealItem) {
    this.cart.removeItem(meal);

    this.orderChanged.emit(this.order);
  }

  public havePrevDays(): boolean {
    if (this.order.deliveryDates.length > 2) {
      const currentDateIndex = this.order.deliveryDates.findIndex(x => x.date === this.selectedDate);

      return currentDateIndex === this.order.deliveryDates.length - 1;
    }
    return false;
  }

  public haveNextDays(): boolean {
    if (this.order.deliveryDates.length > 2) {
      const currentDateIndex = this.order.deliveryDates.findIndex(x => x.date === this.selectedDate);

      return currentDateIndex === 0;
    }
    return false;
  }

  public getOrderDay(shift: number): Date {
    const currentDateIndex = this.order.deliveryDates.findIndex(x => x.date === this.selectedDate);

    if (shift === -1) {
      if (currentDateIndex === 0) {
        return null;
      }
      return this.order.deliveryDates[currentDateIndex - 1].date;

    } if (shift === 0) {
      return this.selectedDate;
    }
    if (currentDateIndex === this.order.deliveryDates.length - 1) {
      return null;
    }
    return this.order.deliveryDates[currentDateIndex + 1].date;
  }

  // eslint-disable-next-line consistent-return
  public onDayClick(date: Date) {
    const currentDateIndex = this.order.deliveryDates.findIndex(x => x.date === this.selectedDate);
    const clickedDateIndex = this.order.deliveryDates.findIndex(x => x.date === date);
    const cart = this.order.getDay(date);

    if (clickedDateIndex < currentDateIndex || ((clickedDateIndex - currentDateIndex) === 1 && !this.isNextDisabled())) {
      this.changeDate(date);
    } else {
      return false;
    }

    if (clickedDateIndex > currentDateIndex && this.isNextDisabled()) {
      return false;
    }

    if (cart.getItemsCount() > 0) {
      this.changeDate(date);
    } else {
      return false;
    }
  }

  public changeDate(date: Date): void {
    this.isVeg = false;
    this.isPremium = false;
    this.selectedDate = date;
    this.meals = null;

    this.cart = this.order.getDay(this.selectedDate);

    this.loadMealsByDate();
  }

  public footerText(): string {
    const price = this.cart.getSubtotal();

    if (price >= MIN_ORDER_FREE_DELIVERY) {
      return '';
    }

    const number = formatNumber(MIN_ORDER_FREE_DELIVERY - price, 'ru', '1.0-2');
    return `Закажите еще на ${number}<span class="ruble">₽</span> для доставки 0<span class="ruble">₽</span>`;
  }

  public nextButtonText(): string {
    const ccals = this.cart.getCcalCount();
    const price = this.cart.getSubtotal();

    if (price < this.minimalCartPrice) {
      const priceLeft = this.minimalCartPrice - price;

      return 'Не хватает ' + formatNumber(priceLeft, 'ru', '1.0-2') + '₽';
    }

    const number = formatNumber(price, 'ru', '1.0-2');
    return `Дальше ${number}₽ / ${formatNumber(ccals, 'ru', '1.0-0')} ккал`;
  }

  public nextButtonSubtext(): string {
    const ccals = this.order.getCcalCount();
    const price = this.order.getMealsPrice();

    return price !== 0
      ? 'Всего на ' + formatNumber(price, 'ru', '1.0-2') + '₽ / ' + formatNumber(ccals, 'ru', '1.0-0') + ' ккал'
      : '';
  }

  public isNextDisabled(): boolean {
    const price = this.cart.getSubtotal();

    return price < this.minimalCartPrice;
  }

  public onNextClick(): void {
    const currentDateIndex = this.order.deliveryDates.findIndex(x => x.date === this.selectedDate);

    this.rudderstack.track(CommonEventsEnum.dishesSelected);
    if (this.order.deliveryDates.length > currentDateIndex + 1) {
      this.changeDate(this.order.deliveryDates[currentDateIndex + 1].date);
    } else {
      this.mealsSelected.emit(this.order);
    }
  }

}
