import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { takeUntil, catchError, switchMap, finalize } from 'rxjs/operators';
import { throwError, Subject, BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngxs/store';

import { environment } from 'src/environments/environment';
import { CommonEventsEnum, LocalStorageKeysEnum } from '@shared/enums';
import { AuthService, ProfileApiService, RudderStackService } from '@shared/services';
import { AuthData } from '@shared/models';
import { getYandexUserId, getGoogleUserId } from '@shared/utils';
import { SetProfile } from '@store/profile';

declare let dataLayer: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  public isProduction = environment.production;

  public phone = new FormControl('', [Validators.required, Validators.minLength(10)]);
  public password = new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]);

  public step = 1;
  public defaultTimeLeft = 60;
  public timeLeft: number;
  public interval;

  public phoneMessage: string;
  public passwordMessage: string;
  public isLoading$ = new BehaviorSubject(false);
  public isSubmitingCode = false;

  private ngUnsubscribe = new Subject();

  constructor(
    private titleService: Title,
    private router: Router,
    private authService: AuthService,
    private cookieService: CookieService,
    private toastr: ToastrService,
    private profileApiService: ProfileApiService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private rudderstack: RudderStackService,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Авторизация');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onPhoneSubmit(): void {
    this.rudderstack.track(CommonEventsEnum.getConfirmCode);

    this.isLoading$.next(true);
    this.authService.getCode('7' + this.phone.value)
      .pipe(
        finalize(() => this.isLoading$.next(false)),
        catchError((error: any) => {
          return this.onPhoneError(error.error?.error ? error.error.error : this.getErrorFromCode(error.status));
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.startTimer();
        this.step = 2;

        this.cdr.markForCheck();
      }, (error) => {
        if (error) {
          this.toastr.error(error);
        }
      });
  }

  public onPhonePaste(e: ClipboardEvent): void {
    const clipboardText = e.clipboardData.getData('text');

    let valueToPaste = clipboardText;

    if (clipboardText.startsWith('+7')) {
      valueToPaste = clipboardText.slice(2, clipboardText.length);
    }

    if (clipboardText.startsWith('7') || clipboardText.startsWith('8')) {
      valueToPaste = clipboardText.slice(1, clipboardText.length);
    }

    setTimeout(() => {
      this.phone.patchValue(valueToPaste);
    }, 10);
  }

  public onPhoneError(error) {
    this.phoneMessage = error;

    return throwError(error);
  }

  public onPasswordSubmit(): void {
    const phone = '7' + this.phone.value;

    this.isSubmitingCode = true;

    this.isLoading$.next(true);
    this.authService.submitCode(phone, this.password.value)
      .pipe(
        switchMap((data: AuthData) => {
          this.authService.authUser(phone, data.accessToken, data.refreshToken);

          return this.profileApiService.getProfile();
        }),
        catchError((error: any) => this.onPasswordError(
          error?.error?.error
            ? error.error.error
            : this.getErrorFromCode(error.status))
        ),
        finalize(() => this.isLoading$.next(false)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((profile) => {
        if (!profile) {
          this.pushFirstAuthEvent();
          const newProfile = {
            googleClientId: getGoogleUserId(this.cookieService),
            yandexClientId: getYandexUserId(this.cookieService)
          };
          this.saveProfile(newProfile);
        } else if (!profile.googleClientId || !profile.yandexClientId) {
          profile.googleClientId = getGoogleUserId(this.cookieService);
          profile.yandexClientId = getYandexUserId(this.cookieService);
          this.pushAuthEvent(profile);
          this.saveProfile(profile);
        } else {
          this.pushAuthEvent(profile);
        }

        this.store.dispatch(new SetProfile(profile));
        this.cdr.markForCheck();

        if (profile?.address && profile?.apartment) {
          this.router.navigate(['/cabinet']);
        } else {
          this.router.navigate(['/welcome']);
        }

        this.isSubmitingCode = false;
      },
      (error: string) => {
        if (error) {
          this.toastr.error(error);
        }
      });
  }

  public pushFirstAuthEvent(): void {
    this.profileApiService.getClientGuid()
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(crmGuid => {
        localStorage.setItem(LocalStorageKeysEnum.userGuid, crmGuid);

        this.pushAuthEvent({
          crmGuid,
          yandexClientId: getYandexUserId(this.cookieService),
          googleClientId: getGoogleUserId(this.cookieService)
        });
      });
  }

  public pushAuthEvent(profile): void {
    if (dataLayer?.push) {
      const eventData = {
        event: 'authorization',
        userId: profile.crmGuid,
        yaclientId: profile.yandexClientId,
        gaclientId: profile.googleClientId
      };
      dataLayer.push(eventData);
    }
  }

  public getErrorFromCode(code: any): string {
    if (code === 0) {
      return 'Превышен лимит запросов. Пожалуйста, подождите и попробуйте снова.';
    }

    return 'Неизвестная ошибка (' + code + '). Пожалуйста, обратитесь в службу поддержки.';
  }

  public onPasswordError(error) {
    this.isSubmitingCode = false;
    this.passwordMessage = error;
    this.password.setValue('');
    this.cdr.markForCheck();

    return throwError(error);
  }

  public onPasswordInput(event): void {
    this.passwordMessage = '';

    const pattern = /[0-9]/;
    if (!pattern.test(event.data)) {
      event.preventDefault();
      return;
    }

    if (this.isPasswordValid()) {
      this.onPasswordSubmit();
    }
  }

  public onPasswordPaste(event): void {
    const pattern = /[0-9]*/;
    const value = event.clipboardData.getData('text/plain');
    const firstSigns = value.substr(0, 4);

    if (pattern.test(firstSigns)) {
      this.password.setValue(firstSigns);
      this.onPasswordSubmit();
    }
  }

  public getPasswordDigit(num: number): string {
    if (!this.password.value) {
      return '';
    }

    return this.password.value.toString().length > num ? this.password.value.toString()[num] : '';
  }

  public getPasswordDigitActivness(num: number): string {
    if (!this.password.value) {
      return '';
    }

    return this.password.value.toString().length > num ? 'active' : '';
  }

  public onResend(): void {
    this.isLoading$.next(true);
    this.authService.getCode('7' + this.phone.value)
      .pipe(
        finalize(() => this.isLoading$.next(false)),
        catchError((error: any) => this.onPasswordError(error)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.startTimer();
      }, (error: string) => {
        if (error) {
          this.toastr.error(error);
        }
      });
  }

  public isPhoneValid(): boolean {
    return this.phone.value.length === 10;
  }

  public isPasswordValid(): boolean {
    return this.password.value.length === 4;
  }

  public startTimer(): void {
    this.timeLeft = this.defaultTimeLeft;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft -= 1;
      } else {
        clearInterval(this.interval);
      }

      this.cdr.markForCheck();
    }, 1000);

    this.cdr.markForCheck();
  }

  private saveProfile(data): void {
    this.profileApiService.saveProfile(data)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe();
  }
}
