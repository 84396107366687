import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AgmCoreModule } from '@agm/core';

import { environment } from 'src/environments/environment';
import { PipesModule } from '../pipes';
import { DirectivesModule } from '../directive';
import { HeaderComponent } from './header';
import { AddressFormComponent } from './address-form';
import { AddressMapComponent } from './address-map';
import { DaySelectorComponent } from './day-selector';
import { DateListComponent } from './date-list';
import { MealListComponent } from './meal-list';
import { OrderListComponent } from './order-list';
import { DishPictureComponent } from './dish-picture';
import { DishItemComponent } from './dish-item';
import { DishModalComponent } from './dish-modal';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

export const components = [
  HeaderComponent,
  AddressFormComponent,
  AddressMapComponent,
  DaySelectorComponent,
  DateListComponent,
  MealListComponent,
  OrderListComponent,
  DishPictureComponent,
  DishItemComponent,
  DishModalComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    PipesModule,
    DirectivesModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
      timeOut: 4000,
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapToken
    }),
    NgxMaskModule.forRoot(options),
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components,
    ToastrModule,
    NgxMaskModule,
    LazyLoadImageModule,
    AgmCoreModule,
  ]
})
export class ComponentsModule { }
