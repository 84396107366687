export const defaultPopupAnimation = {
  incomingOptions: {
    keyframes: [
      { transform: 'translate(100%, 0)' },
      { transform: 'translate(0, 0)' },
    ],
    keyframeAnimationOptions: {
      easing: 'ease-out',
      duration: 350,
    },
  },
  outgoingOptions: {
    keyframes: [
      { transform: 'translate(0, 0)' },
      { transform: 'translate(100%, 0)' },
    ],
    keyframeAnimationOptions: {
      easing: 'ease-out',
      duration: 350
    },
  },
};
