<div class="header" [ngClass]="isSolidBackground ? 'solid' : ''">
  <div class="header-container">
    <button [hidden]="step < 2 || isMenuVisible" [routerLink]="[]" class="back-btn" (click)="onBackClick()">
      <img src="/assets/images/back-btn.svg" />
    </button>
    <button [routerLink]="[]" class="menu-btn" (click)="onMenuToggle()" [hidden]="!isLoggedIn()">
      <img class="open-menu-img" src="/assets/images/menu-btn.svg" [hidden]="isMenuVisible" />
      <img class="close-menu-img" src="/assets/images/menu-close-btn.svg" [hidden]="!isMenuVisible" />
    </button>
  </div>
</div>

<div class="menu-container" [ngClass]="isMenuVisible ? 'visible' : ''">
  <div class="menu">
    <div class="links">
      <a [routerLink]="['/']" *ngIf="!isOnboarding" class="link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Заказ</a>
      <a [routerLink]="['/rules']" class="link" [routerLinkActive]="['active']">Правила</a>
      <a [routerLink]="['/profile']" *ngIf="!isOnboarding" class="link" [routerLinkActive]="['active']">Профиль</a>
      <a [routerLink]="['/address']" *ngIf="!isOnboarding" class="link" [routerLinkActive]="['active']">Адрес</a>
      <!-- <a [routerLink]="['/cards']" *ngIf="!isOnboarding" class="link" [routerLinkActive]="['active']">Карты</a> -->
      <a (click)="onLogout()" class="link">Выйти</a>
    </div>
    <div class="footer">
      <div class="phone-container">
        <a href="tel:84991102220" class="phone">
          <img src="/assets/images/phone.svg" /> 8 (499) 110-22-20
        </a>
      </div>
      <div class="chats-container">
        <a href="https://mssg.me/r/m/5dde90dc2ca22d004daee3fe" target="_blank" rel="noopener nofollow" class="chat-link telegram">
          <img src="/assets/images/telegram.svg" /> Telegram
        </a>
        <a href="https://api.whatsapp.com/send?phone=79776005353" target="_blank" rel="noopener nofollow" class="chat-link whatsapp">
          <img src="/assets/images/whatsapp.svg" /> Whatsapp
        </a>
      </div>
    </div>
  </div>
</div>
