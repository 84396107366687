<div class="payment-container">
    <div *ngIf="paymentId && created && !isPaid && !isPaymentError && showControls" class="payment-block">
        <div class="payment-msg">
            <img class="payment-img check" src='/assets/images/check.svg' />
            <div class="payment-text">Оплата заказа</div>
            <button type="button" class="btn" (click)="pay()">Оплатить</button>
            <div *ngIf="orderNumber()" class="order-number">Номер заказа: {{ orderNumber() }}</div>
            <div class="payment-hint">Если хотите отменить заказ и создать<br/>другой — свяжитесь с нами. Извините, что это неудобно — скоро переделаем.</div>
            <div class="social-links">
                <div class="phone-container">
                    <a href="tel:84991102220" class="phone">
                    <img src="/assets/images/phone.svg" /> 8 (499) 110-22-20
                    </a>
                </div>
                <div class="chats-container">
                    <a href="https://mssg.me/r/m/5dde90dc2ca22d004daee3fe" target="_blank" rel="noopener nofollow" class="chat-link telegram">
                    <img src="/assets/images/telegram.svg" /> Telegram
                    </a>
                    <a href="https://api.whatsapp.com/send?phone=79776005353" target="_blank" rel="noopener nofollow" class="chat-link whatsapp">
                    <img src="/assets/images/whatsapp.svg" /> Whatsapp
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="paymentId && !created && !isPaid && !isPaymentError &&  showControls" class="payment-block">
        <div *ngIf="!this.created" class="payment-msg">
            <img class="payment-img banana" src='/assets/images/banana.jpg' />
            <div class="payment-text">У вас есть<br/>неоплаченный заказ!</div>
            <button type="button" class="btn" (click)="pay()">Оплатить</button>
            <div *ngIf="orderNumber()" class="order-number">Номер заказа: {{ orderNumber() }}</div>
            <div class="payment-hint">Если хотите отменить заказ и создать<br/>другой — свяжитесь с нами. Извините, что это неудобно — скоро переделаем.</div>
            <div class="social-links">
                <div class="phone-container">
                    <a href="tel:84991102220" class="phone">
                    <img src="/assets/images/phone.svg" /> 8 (499) 110-22-20
                    </a>
                </div>
                <div class="chats-container">
                    <a href="https://mssg.me/r/m/5dde90dc2ca22d004daee3fe" target="_blank" rel="noopener nofollow" class="chat-link telegram">
                    <img src="/assets/images/telegram.svg" /> Telegram
                    </a>
                    <a href="https://api.whatsapp.com/send?phone=79776005353" target="_blank" rel="noopener nofollow" class="chat-link whatsapp">
                    <img src="/assets/images/whatsapp.svg" /> Whatsapp
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isPaid && showControls" class="payment-block">
        <div class="payment-msg fruit">
            <img class="payment-img check" src='/assets/images/check.svg' />
            <div class="payment-text">Оплачено!</div>
            <button type="button" class="btn" (click)="onOkClick()">Жду доставку!</button>
        </div>
    </div>
    <div *ngIf="paymentId && !isPaid && isPaymentError && showControls" class="payment-block">
        <div class="payment-msg">
            <img class="payment-img apple" src='/assets/images/apple.jpg' />
            <div class="payment-text">Платеж не прошел</div>
            <button type="button" class="btn" (click)="pay()">Попробовать еще раз</button>
            <div *ngIf="orderNumber()" class="order-number">Номер заказа: {{ orderNumber() }}</div>
            <div class="payment-hint">Если хотите отменить заказ и создать<br/>другой — свяжитесь с нами. Извините, что это неудобно — скоро переделаем.</div>
            <div class="social-links">
                <div class="phone-container">
                    <a href="tel:84991102220" class="phone">
                    <img src="/assets/images/phone.svg" /> 8 (499) 110-22-20
                    </a>
                </div>
                <div class="chats-container">
                    <a href="https://mssg.me/r/m/5dde90dc2ca22d004daee3fe" target="_blank" rel="noopener nofollow" class="chat-link telegram">
                    <img src="/assets/images/telegram.svg" /> Telegram
                    </a>
                    <a href="https://api.whatsapp.com/send?phone=79776005353" target="_blank" rel="noopener nofollow" class="chat-link whatsapp">
                    <img src="/assets/images/whatsapp.svg" /> Whatsapp
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
