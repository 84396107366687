import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { takeUntil, catchError, finalize, filter } from 'rxjs/operators';
import { throwError, Subject, BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { CommonEventsEnum, LocalStorageKeysEnum, SessionStorageKeysEnum } from '@shared/enums';
import { ProfileApiService, RudderStackService } from '@shared/services';
import { AddressData, AddressChangedEvent } from '@shared/models';
import { getGoogleUserId, getYandexUserId } from '@shared/utils';

declare let dataLayer: any;

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit, OnDestroy {

  public nameControl = new FormControl('', [Validators.required]);
  public step = 1;
  public address: AddressData;
  public isInPolygon: boolean;
  public isAddressSelected: boolean;
  public isHouseSelected: boolean;
  public saveProfileError: string;
  public coordinates = null;
  public isSaving$ = new BehaviorSubject(false);

  private ngUnsubscribe = new Subject();

  constructor(
    private titleService: Title,
    private router: Router,
    private cookieService: CookieService,
    private profileApiService: ProfileApiService,
    private rudderstack: RudderStackService,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Привет!');

    this.profileApiService.isProfileFilled()
      .pipe(
        filter(isFilled => isFilled),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        this.router.navigate(['/cabinet']);
      });

    sessionStorage.setItem(SessionStorageKeysEnum.new_user, '1');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onBack(): void {
    if (!this.isSaving$.getValue()) {
      this.step = Math.max(this.step - 1, 1);
    }
  }

  public onNextClick(): void {
    if (this.isNameValid()) {
      this.rudderstack.track(CommonEventsEnum.enterName);
      this.step = 2;
    }
  }

  public isNameValid(): boolean {
    return !!this.nameControl.value;
  }

  public isAddressValid(): boolean {
    return !!(
      this.isAddressSelected &&
      this.isHouseSelected &&
      this.isInPolygon &&
      this.address &&
      this.address.address &&
      this.address.apartment
    );
  }

  public addressNextBtnText(): string {
    if (!this.isAddressSelected) {
      return 'Выберите адрес из списка';
    }

    if (!this.isHouseSelected) {
      return 'Введите данные о доме';
    }

    if (!this.isInPolygon) {
      return 'Адрес не входит в зону доставки';
    }

    if (!this.address.apartment) {
      return 'Введите данные о квартире';
    }

    return 'Дальше';
  }

  public onAddressChanged(address: AddressChangedEvent): void {
    this.address = address.address;
    this.isInPolygon = address.isInPolygon;
    this.isAddressSelected = address.isSelected;
    this.isHouseSelected = address.isHouseSelected;
    this.coordinates = address?.coordinates || null;
  }

  public onSubmit(): void {
    if (!this.isNameValid() || !this.isAddressValid()) {
      return;
    }

    this.saveProfileError = '';

    const profile = {
      phone: this.profileApiService.getPhone(),
      name: this.nameControl.value,
      address: this.address.address,
      apartment: this.address.apartment,
      deliveryComment: this.address.comment,
      googleClientId: getGoogleUserId(this.cookieService),
      yandexClientId: getYandexUserId(this.cookieService),
      coordinates: this.coordinates,
      approvedCoordinates: !!this.coordinates,
    };

    this.rudderstack.track(CommonEventsEnum.enterProfileInfo);
    this.isSaving$.next(true);
    this.profileApiService.saveProfile(profile)
      .pipe(
        finalize(() => this.isSaving$.next(false)),
        catchError((error) => this.onSaveError(error.error.error ? error.error.error : 'Неизвестная ошибка')),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        if (dataLayer && dataLayer.push) {
          const userGuid = localStorage.getItem(LocalStorageKeysEnum.userGuid);

          dataLayer.push({
            event: 'newUser',
            userId: userGuid
          });
        }

        this.router.navigate(['/']);
      });
  }

  public onSaveError(error) {
    this.saveProfileError = error;

    return throwError(error);
  }

}
