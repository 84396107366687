import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ImageColorEnum, ImageFileTypeEnum, ImageSizeEnum, ImageTypeEnum } from '@shared/enums';

@Component({
  selector: 'app-dish-picture',
  templateUrl: './dish-picture.component.html',
  styleUrls: ['./dish-picture.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DishPictureComponent {
  @Input() public label = 'justfood';

  @Input() public imageType: ImageTypeEnum = ImageTypeEnum.sitePreview;

  @Input() public dishCode: string;

  @Input() public defaultImage: string;

  @Input() public errorImage: string;

  private imageParamsMap = {
    [ImageTypeEnum.sitePreview]: {
      size: ImageSizeEnum.medium,
      backgroundColor: ImageColorEnum.transparent,
    }
  };

  public readonly ImageTypeEnum = ImageTypeEnum;
  public readonly ImageFileTypeEnum = ImageFileTypeEnum;

  public get imageSrc(): string {
    if (!this.dishCode || !this.imageType) { return ''; }

    const params = this.imageParamsMap[this.imageType];

    return `https://crm.justfood.pro/api/files/${this.dishCode}-${params.size}-${params.backgroundColor}`;
  }

}
