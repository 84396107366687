import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Order, PromocodeInfo, UnpaidResponse } from '@shared/models';
import { SessionStorageKeysEnum } from '@shared/enums';

@Injectable({
  providedIn: 'root'
})
export class OrderApiService {

  private readonly apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
  ) {}

  getUnpaid(): Observable<UnpaidResponse> {
    return this.http.get<UnpaidResponse>(this.apiUrl + '/custom/payment/unpaid');
  }

  createOrder(order: Order) {
    const days = order.getItems().map((dayItem) => {
      return {
        date: this.getUtcDate(dayItem.day),
        deliveryTime: order.fromTime + '-' + order.toTime,
        deliveryPrice: dayItem.cart.getDayDeliveryPrice(),
        meals: dayItem.cart.getItems().map((mealItem) => {
          return {
            Meal: {
              Code: mealItem.meal.code,
              Price: mealItem.meal.price,
              FoodCost: mealItem.meal.foodCost,
              Name: mealItem.meal.name,
              FabricName: mealItem.meal.fabricName,
              DCCode: mealItem.meal.dcCode
            },
            Count: mealItem.count
          };
        })
      };
    });

    const orderRequest = {
      Address: order.profile.address,
      Apartment: order.profile.apartment,
      DeliveryComment: order.profile.deliveryComment,
      Days: days,
      UseDevices: order.useDevices,
      Promocode: order.promocode
    };

    return this.http.post(this.apiUrl + '/custom/order', orderRequest);
  }

  loadOrder(): Order {
    const order = new Order();
    const orderRawObj = JSON.parse(sessionStorage.getItem('order'));

    if (orderRawObj) {
      order.copyFrom(orderRawObj);
    }

    return order;
  }

  saveOrder(order: Order) {
    sessionStorage.setItem(SessionStorageKeysEnum.order, JSON.stringify(order));
  }

  checkPromocode(promocode: string, order: Order): Observable<PromocodeInfo> {
    const days = order.getItems().map((dayItem) => {
      return {
        date: this.getUtcDate(dayItem.day),
        deliveryPrice: dayItem.cart.getDayDeliveryPrice(),
        meals: dayItem.cart.getItems().map((mealItem) => {
          return {
            Meal: {
              Price: mealItem.meal.price
            },
            Count: mealItem.count
          };
        })
      };
    });

    const requestData = {
      Promocode: promocode,
      Days: days
    };

    return this.http.post(this.apiUrl + '/custom/check-promocode', requestData).pipe(
      map((data: any) => {
        return {
          promocodeType: data.promocodeType,
          discountUnits: data.discountUnits,
          discount: data.discount
        };
      }));
  }

  private getUtcDate(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  }

}
