<app-header
  [isOnboarding]="true"
  [step]="step"
  (back)="onBack()"></app-header>

<div class="welcome-container">
  <div class="global-preloader blocking" [hidden]="!(isSaving$ | async)">
    <img src="/assets/images/preloader.gif" alt="" />
  </div>

  <form class="welcome-form" (ngSubmit)="onSubmit()">
    <ng-container *ngIf="step === 1">
      <div class="form-title">Привет, <span class="name">{{ nameControl.value }}</span></div>

      <div class="form-group">
        <label class="input-hint">Имя</label>
        <input [formControl]="nameControl" class="name-input" type="text" name="name" placeholder="Введите имя" autocomplete="off" maxlength="25" required />
      </div>

      <div class="footer">
        <button type="button" class="btn" (click)="onNextClick()" [disabled]="!isNameValid()">Дальше</button>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 2">
      <div class="form-title form-title-gray">Привет, {{ nameControl.value }}</div>
      <div class="form-title">Куда везем?</div>

      <app-address-form [address]="address" (addressChanged)="onAddressChanged($event)" [showHint]="true"></app-address-form>

      <div class="error">{{saveProfileError}}</div>

      <div class="footer">
        <button type="submit" class="btn" [disabled]="!isAddressValid() || (isSaving$ | async)">{{addressNextBtnText()}}</button>
      </div>
    </ng-container>
  </form>
</div>
