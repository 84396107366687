import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@shared/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input() isSolidBackground: boolean;
  @Input() isOnboarding: boolean;
  @Input() step: number;

  @Output() back: EventEmitter<any> = new EventEmitter();

  public isMenuVisible: boolean;

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.isMenuVisible = false;
  }

  public onBackClick(): void {
    this.back.emit(null);
  }

  public onMenuToggle(): void {
    this.isMenuVisible = !this.isMenuVisible;
  }

  public isLoggedIn(): boolean {
    return this.authService.isAuthenticated();
  }

  public onLogout(): void {
    this.authService.logout();
  }

}
