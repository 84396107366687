<app-header [isSolidBackground]="false" [isOnboarding]="false" [step]="step" (back)="onBack()"></app-header>

<div class="cabinet-container">
  <ng-template #dateListForm [ngIf]="step === 1">
    <app-date-list [order]="order" (orderChanged)="onOrderChanged($event)" (datesSelected)="onDatesSelected($event)"></app-date-list>
  </ng-template>

  <ng-template #mealListForm [ngIf]="step === 2">
    <app-meal-list [order]="order" (orderChanged)="onOrderChanged($event)" (mealsSelected)="onMealsSelected($event)"></app-meal-list>
  </ng-template>

  <ng-template #orderListForm [ngIf]="step === 3">
    <app-order-list [order]="order" (orderChanged)="onOrderChanged($event)" (orderCreated)="onOrderCreated($event)"></app-order-list>
  </ng-template>
</div>
