import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {

  constructor(
    private titleService: Title,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Меню');
  }

  public onBack(): void {
    this.router.navigate(['/login']);
  }

}
