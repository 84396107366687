import { Component, OnInit } from '@angular/core';

import { BrowserLoadingScenarioService, RudderStackService } from '@shared/services';
import { CommonEventsEnum } from '@shared/enums';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [BrowserLoadingScenarioService],
})
export class AppComponent implements OnInit {

  constructor(
    private rudderstack: RudderStackService,
    private browserLoadingScenarioService: BrowserLoadingScenarioService,
  ) {}

  ngOnInit(): void {
    this.browserLoadingScenarioService.init().then(() => {
      this.rudderstack.track(CommonEventsEnum.pageLoaded);
    });

    sessionStorage.clear();
  }

}
