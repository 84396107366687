<div class="order-list-container">
  <div class="order-header">
    <div class="title">Итог</div>
    <div *ngIf="!isPromocodeApplied()" class="total-price">{{ order.getFullPrice() | number:'1.0-2'}} Р</div>
    <div *ngIf="isPromocodeApplied()" class="total-price">
      <span class="old-price">{{ order.getFullPrice() | number:'1.0-2' }} Р</span>{{ order.getFullPriceWithDiscount() | number:'1.0-2'}} Р
    </div>
  </div>
  <div class="order-info">
    <div class="global-preloader solid" [hidden]="!(isSaving$ | async)">
      <div class="preloader-text">Формируем заказ...</div>
      <img src="/assets/images/preloader.gif" />
    </div>

    <div *ngFor="let orderDay of order.getItems();"
      class="order-day">
      <div class="day-header">
        <div class="day-title">
          {{orderDay.day | date:'EEEE, d MMMM' | capitalize }}
        </div>
        <div class="day-subtitle">
          <span class="day-sub-text">
            <span class="sub-text-item">
              <b>{{orderDay.cart.getProteinsCount() | number:'1.0-0'}}</b> Б
            </span>
            <span class="sub-text-item">
              <b>{{orderDay.cart.getFatsCount() | number:'1.0-0'}}</b> Ж
            </span>
            <span class="sub-text-item">
              <b>{{orderDay.cart.getCarbsCount() | number:'1.0-0'}}</b> У
            </span>
          </span>
          <span class="day-sub-text">
            <b>{{orderDay.cart.getCcalCount() | number:'1.0-0'}}</b> ккал
          </span>
        </div>
      </div>
      <div class="day-details">
        <div *ngFor="let cartItem of orderDay.cart.getItems();"
          class="meal">
          <div class="meal-count">
            {{cartItem.count}}x
          </div>
          <div class="meal-name">
            {{cartItem.meal.name}}
          </div>
          <div class="meal-price">
            {{cartItem.meal.price * cartItem.count | number:'1.0-2'}}<span class="ruble">₽</span>
          </div>
        </div>
      </div>
      <div class="day-footer">
        <div class="meal">
          <div class="meal-name">
            Доставка
            <i
              *ngIf="getDeliveryPrice(orderDay) > 0"
              [appTooltip]="getDeliveryPriceTooltip(orderDay)"
              placement="top"
              tooltipClass="info-tooltip"
              class="icon-meal">!</i>
          </div>
          <div class="meal-price">
            {{getDeliveryPrice(orderDay)}}<span class="ruble">₽</span>
          </div>
        </div>
        <div class="meal type-summary">
          <div class="meal-name">
            Итого
          </div>
          <div class="meal-price">
            {{orderDay.cart.getSubTotalWithDelivery()}}<span class="ruble">₽</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row devices-row">
      <div class="label item-left">Приборы:</div>
      <div class="devices-switch">
        <label class="switch">
          <input type="checkbox" [checked]="order.useDevices" (change)="onDevicesChecked()">
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div class="address row">
      <span class="label item-left">Адрес:</span>
      <span class="value">{{ order.profile.address }}, {{ order.profile.apartment }} <a [routerLink]="['/address']" class="address-edit-btn" title="Изменить"><img src="/assets/images/edit-btn.svg" /></a></span>
    </div>
    <div *ngIf="order.profile.deliveryComment" class="delivery-comment row">
      <span class="label item-left">Комментарий:</span>
      <span class="value">{{ order.profile.deliveryComment }}</span>
    </div>
    <div class="time row">
      <span class="label item-left">Время доставки:</span>
      <span class="value">{{ order.fromTime }} - {{ order.toTime }}</span>
    </div>
    <div class="promocode">
      <div class="promocode-input-container">
        <input [formControl]="promocodeControl" class="promocode-input" type="text" name="promocode" placeholder="Промокод" autocomplete="off" maxlength="100" [attr.disabled]="isPromocodeApplied() ? '' : null" />
        <button type="button" class="btn promocode-btn" (click)="onApplyPromocode()" [disabled]="!promocodeControl.value.length && !isPromocodeApplied() || isCheckingPromocode">{{applyPromocodeText()}}</button>
      </div>
      <div class="promocode-hint">{{promocodeHint}}</div>
    </div>
  </div>
  <div *ngIf="orderError" class="error">
    <div class="error-content">
      <img class="error-img" src='/assets/images/apple.jpg' />
      <div class="error-text">Упс!<br/>{{orderError}}</div>
      <div class="error-hint">Сформируйте заказ заново.<br/>Извините, что это неудобно — скоро переделаем.</div>
      <button type="button" class="btn" (click)="onTryAgainClick()">Собрать заказ снова</button>
    </div>
  </div>
  <div class="footer order-footer">
    <button type="button" class="btn" (click)="onPayClicked()" [disabled]="(isSaving$ | async)">Оплатить</button>
  </div>
</div>
