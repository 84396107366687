<app-header
  [isOnboarding]="isOnboarding$ | async "
  [step]="2"
  (back)="onBack()"></app-header>

<div class="rules-container">
  <div class="form-header">
    <div class="form-title">Правила</div>
  </div>
  <div class="rules">
    <div class="rule-description">Здесь вы можете заказать коробочки с готовой здоровой едой от justfood на один или несколько дней.</div>

    <div class="rule">
      <div class="rule-title">Главное</div>
      <ul class="rule-text">
        <li>каждый день уникальное меню</li>
        <li>срок годности блюд от 3 суток, вы можете заказывать еду сразу на несколько дней</li>
        <li>минимальный заказ на одну доставку {{ MIN_ORDER_PRICE }} руб.</li>
      </ul>
    </div>

    <div class="rule">
      <div class="rule-title">Доставка</div>
      <ul class="rule-text">
        <li>доставка при заказе до 2 000 рублей — 290 рублей. Если сумма больше 2 000 рублей, доставка бесплатная.</li>
        <li>можно выбрать доставку на один или несколько дней</li>
        <li>доставляем каждый день, кроме воскресенья, в выбранный двухчасовой интервал с 6 утра до 11 дня</li>
        <li>курьер может ожидать вас не более 15 минут, повторный приезд стоит 250 руб</li>
      </ul>
    </div>

    <div class="rule">
      <div class="rule-title">Безопасность</div>
      <ul class="rule-text">
        <li>доставляем бесконтактно</li>
        <li>курьеры ежедневно проходят осмотр, работают в масках и перчатках</li>
        <li>производство сертифицировано по ХАССП (международный стандарт безопасности пищевых производств)
          <br/>
          Подробнее по <a target="_blank" href="https://www.justfood.pro/blog/mery-bezopasnosti-v-svyzi-s-koronavirusom">ссылке</a>.
        </li>
      </ul>
    </div>

    <div class="rule">
      <div class="rule-title">Оферта</div>
      <div class="rule-text">
        C договором можно ознакомиться по <a target="_blank" href="/assets/documents/oferta_custom.pdf">ссылке</a>.
      </div>
    </div>
  </div>
</div>
