import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { LocalStorageKeysEnum } from '@shared/enums';
import { OrderApiService, ProfileApiService } from '@shared/services';
import { environment } from 'src/environments/environment';

declare let cp: any;
declare let dataLayer: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit, OnDestroy {

  public isPaid = false;
  public isPaymentError = false;
  public showControls = false;
  public created = false;
  public paymentId: string;
  public amount: number;

  private ngUnsubscribe = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private orderApiService: OrderApiService,
    private profileApiService: ProfileApiService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        this.created = params.created === 'true';
        if (!this.created) {
          this.showControls = true;
        }
      });

    this.orderApiService.getUnpaid()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((unpaidInfo: any) => {
        if (!unpaidInfo.thereIsUnpaidPayment) {
          this.router.navigate(['/']);
        } else {
          sessionStorage.clear();

          this.paymentId = unpaidInfo.paymentId;
          this.amount = unpaidInfo.amount;

          if (this.created) {
            this.pay();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onOkClick(): void {
    this.router.navigate(['/']);
  }

  public orderNumber(): string {
    const num = localStorage.getItem(LocalStorageKeysEnum.lastOrderNumber);

    return num || '';
  }

  public pay(): void {
    this.showControls = false;

    const widget = new cp.CloudPayments();

    widget.charge({
      publicId: environment.cloudPaymentsPublicId,
      description: 'Оплата заказа justfood',
      amount: this.amount,
      currency: 'RUB',
      invoiceId: this.paymentId,
      skin: 'mini'
    },
    () => {
      if (dataLayer && dataLayer.push) {
        const orderGuid = localStorage.getItem(LocalStorageKeysEnum.lastOrderGuid);
        const orderNumber = localStorage.getItem(LocalStorageKeysEnum.lastOrderNumber);
        const lastOrderMealsList = JSON.parse(localStorage.getItem(LocalStorageKeysEnum.lastOrderMealsList));
        const userGuid = localStorage.getItem(LocalStorageKeysEnum.userGuid);

        this.profileApiService.getClientOrdersCount()
          .pipe(
            takeUntil(this.ngUnsubscribe)
          )
          .subscribe((ordersCount) => dataLayer.push({
            event: 'purchase',
            userId: userGuid,
            OrderCount: ordersCount === 1 ? 'first' : null,
            id: orderGuid,
            number: orderNumber,
            products: lastOrderMealsList
          })
          );
      }

      this.showControls = true;
      this.isPaid = true;
    },
    (reason) => {
      this.showControls = true;
      if (reason !== 'User has cancelled') {
        this.isPaymentError = true;
      }
    });
  }

}
