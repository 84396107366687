import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';

import { environment } from 'src/environments/environment';
import { ngxsConfig } from './store-config';
import { ProfileState } from './profile';

@NgModule({
  imports: [
    NgxsModule.forRoot([
      ProfileState,
    ], ngxsConfig),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production
    }),
    NgxsSelectSnapshotModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ]
})
export class StoreModule { }
