import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngxs/store';
import { take } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';
import { CookieService } from 'ngx-cookie-service';

import { environment } from 'src/environments/environment';
import { checkIfUser } from '@shared/utils';
import { EventPayload, EventProperties } from '@shared/models';
import { ProfileSelectors } from '@store/profile';
import { RudderstackStorageKeys } from '@shared/enums';

@Injectable({
  providedIn: 'root'
})
export class RudderStackService {

  private readonly apiUrl = `${environment.apiUrl}/rudderstack`;

  private get shouldSendEvent(): boolean {
    if (!isPlatformBrowser(this.platformId)) { return false; }

    return checkIfUser(window.navigator.userAgent);
  }

  private initialReferrer: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private store: Store,
    private cookieService: CookieService,
  ) {}

  public identify(userId: string): void {
    if (!this.shouldSendEvent) { return; }

    const url = `${this.apiUrl}/identify`;
    const requestPayload = this.generateRequestPayload(userId);

    this.http.post(url, requestPayload).subscribe();
  }

  public track(eventName: string, properties?: EventProperties): void {
    if (!this.shouldSendEvent) { return; }

    const url = `${this.apiUrl}/track`;
    const anonymousId = this.cookieService.get('_jft_uid') || 'local_development';;
    const profile = this.store.selectSnapshot(ProfileSelectors.profile);

    const userId = new URL(document.URL).searchParams.get('clientGuid') || profile?.id || null;
    const requestPayload = this.generateRequestPayload(userId, anonymousId, eventName, properties);

    this.http.post(url, requestPayload)
      .pipe(
        take(1),
      )
      .subscribe();
  }

  public saveInitialReferrer(): void {
    if (!isPlatformBrowser(this.platformId)) { return; }

    const storedInitialReferrer = this.localStorage.retrieve(RudderstackStorageKeys.initialReferrer);

    if (storedInitialReferrer) {
      this.initialReferrer = storedInitialReferrer;
      return;
    }

    this.initialReferrer = document.referrer || '$direct';
    this.localStorage.store(RudderstackStorageKeys.initialReferrer, this.initialReferrer);
  }

  private generateRequestPayload(
    userId: string,
    anonymousId?: string,
    eventName?: string,
    properties?: EventProperties
  ): EventPayload {
    return {
      anonymousId,
      userId,
      eventName,
      properties,
      context: {
        userAgent: window.navigator.userAgent,
        page: {
          url: window.location.href,
          path: window.location.pathname,
          referrer: document.referrer,
          initial_referrer: this.initialReferrer
        }
      },
      integrations: {
        All: true
      }
    };
  }
}
