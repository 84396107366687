export enum ImageSizeEnum {
  xSmall = '45x62',
  small = '168x168',
  medium = '280x280',
  big = '375x335',
  w180 = '180x250',
  w200 = '200x280',
  w360 = '360x500',
  w400 = '400x560',
  w560 = '560x560',
}

export enum ImageColorEnum {
  gray = 'f1f1f1',
  transparent = 'transparent',
}

export enum ImageTypeEnum {
  sitePreview,
  icon,
  oldSitePreview,
  newDesign,
  newDesignMobile,
}

export enum ImageFileTypeEnum {
  png = 'png',
  jpg = 'jpg',
  webp = 'webp',
}
