<div class="wrapper type-{{getType(meal.type)}} modification-{{ meal | dishTransform  }}">
  <div class="type-con" *ngIf="meal.veg || meal.premium">
    <div *ngIf="meal.veg" class="type-item">
      <i class="icon-type type-veg"></i>
      <div class="tooltip">Вегетарианское блюдо</div>
    </div>
    <div *ngIf="meal.premium" class="type-item">
      <i class="icon-type type-premium"></i>
      <div class="tooltip">Премиальное блюдо</div>
    </div>
  </div>

  <div *ngIf="meal?.bigCalories" class="big-calories">
    <span>большая порция</span>
  </div>

  <div class="inner">
    <div class="content">
      <div class="image-con">
        <app-dish-picture
          (click)="openModal(meal)"
          class="image"
          [defaultImage]="'assets/images/preloader.gif'"
          [errorImage]="'/assets/images/meals/empty-dish.png'"
          [dishCode]="meal.code">
        </app-dish-picture>
      </div>
      <div class="title-con">
        <div class="title">
          {{meal.name}}
        </div>
      </div>
      <div class="buttons">
        <button *ngIf="isDemo; else controls"
          class="btn-simple btn-buy"
          (click)="priceClick.emit()">
          {{meal.price | number:'1.0-2'}}Р
        </button>
        <ng-template #controls>
          <button *ngIf="itemsInCart === 0"
            class="btn-simple btn-buy"
            (click)="addItem.emit()">
            Добавить / {{meal.price | number:'1.0-2'}}₽
          </button>

          <ng-container *ngIf="itemsInCart !== 0">
            <button class="btn-simple btn-remove" (click)="removeItem.emit()">
              <i class="icon-remove"></i>
            </button>
            <div class="text-count">{{ itemsInCart }}</div>
            <button class="btn-simple btn-add" (click)="addItem.emit()">
              <i class="icon-add"></i>
              {{meal.price | number:'1.0-2'}}Р
            </button>
          </ng-container>
        </ng-template>
      </div>
    </div>
    <div class="dish-footer">
      <div class="footer-item">
        <b>{{meal.weight | number:'1.0-0'}}</b>
        грамм
      </div>
      <div class="footer-item">
        <b>{{meal.calories | number:'1.0-0'}}</b>
        ккал
      </div>
      <div class="footer-item">
        <b>{{meal.carbs | number:'1.0-0'}} г</b>
        углеводы
      </div>
      <div class="footer-item">
        <b>{{meal.fat | number:'1.0-0'}} г</b>
        жиры
      </div>
      <div class="footer-item">
        <b>{{meal.protein | number:'1.0-0'}} г</b>
        белки
      </div>
    </div>
  </div>

</div>
