import { Component, Output, Input, EventEmitter } from '@angular/core';

import { DeliveryDate } from '@shared/models';

@Component({
  selector: 'app-day-selector',
  templateUrl: './day-selector.component.html',
  styleUrls: ['./day-selector.component.css']
})
export class DaySelectorComponent {

  @Output() selectedDatesChanged: EventEmitter<DeliveryDate[]> = new EventEmitter();
  @Input() deliveryDates: DeliveryDate[];
  @Input() selectedDates: DeliveryDate[];

  public currentWeek = this.getWeekNumber(new Date());

  get currentWeekDates(): DeliveryDate[] {
    if (this.deliveryDates) {
      return this.deliveryDates.filter((element) => this.getWeekNumber(element.date) === this.currentWeek);
    }
    return [];
  }

  get nextWeekDates(): DeliveryDate[] {
    if (this.deliveryDates) {
      return this.deliveryDates.filter((element) => this.getWeekNumber(element.date) !== this.currentWeek);
    }
    return [];
  }

  public onSelect(date: DeliveryDate): void {
    if (date.hasOrder) {
      return;
    }

    const selectedIndex = this.selectedDates.findIndex(x => x.date.getTime() === date.date.getTime());
    if (selectedIndex >= 0) {
      this.selectedDates.splice(selectedIndex, 1);
    } else {
      this.selectedDates.push(date);
    }

    this.selectedDates = this.selectedDates.sort((date1: DeliveryDate, date2: DeliveryDate) => {
      return date1.date.getTime() < date2.date.getTime() ? -1 : 1;
    });

    this.selectedDatesChanged.emit(this.selectedDates);
  }

  public isSelected(date: DeliveryDate): boolean {
    return this.selectedDates.findIndex(x => x.date.getTime() === date.date.getTime()) >= 0;
  }

  public isAlmostLate(date: DeliveryDate): boolean {
    if (date.hasOrder) {
      return false;
    }

    const diffTime = (date.date.getTime() - new Date().getTime()) / (3600 * 1000);

    return diffTime <= 16; // 24 - 11 - 3
  }

  public getWeekNumber(date: Date): number {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

    return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
  }

}
