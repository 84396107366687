export function getGoogleUserId(cookieService): string {
  const gaCookieValue = cookieService.get('_ga');

  if (!gaCookieValue) {
    return null;
  }

  const gaItems = gaCookieValue.split('.');
  return gaItems ? gaItems[2] + '.' + gaItems[3] : '';
}
