import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { filter } from 'rxjs/operators';

import { MealItem } from '@shared/models';
import { defaultPopupAnimation } from '@shared/data';
import { DishModalComponent } from '../dish-modal';

@Component({
  selector: 'app-dish-item',
  templateUrl: './dish-item.component.html',
  styleUrls: ['./dish-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DishItemComponent {

  @Input() meal: MealItem;
  @Input() isDemo: boolean;
  @Input() itemsInCart: number;

  @Output() priceClick = new EventEmitter();
  @Output() addItem = new EventEmitter();
  @Output() removeItem = new EventEmitter();

  constructor(
    private dialog: NgDialogAnimationService) {}

  getType(mealType: string): string {
    switch (mealType) {
      case 'Завтраки':
        return 'breakfast';
      case 'Перекусы':
        return 'lunch';
      case 'Салаты':
        return 'salad';
      case 'Горячее':
        return 'hot';
      case 'Супы':
        return 'soup';
      case 'Десерты':
        return 'desert';
      case 'Напитки':
        return 'drinks';
      default:
        return '';
    }
  }

  openModal(meal: MealItem): void {
    const dialog = this.dialog.open(DishModalComponent, {
      maxWidth: 'auto',
      height: '100%',
      position: {
        top: '0',
        right: '0',
        bottom: '0'
      },
      animation: defaultPopupAnimation,
      data: { meal }
    });

    dialog
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(() => (this.isDemo ? this.priceClick.emit() : this.addItem.emit()));
  }
}
