import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { MealItem } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class MealsApiService {

  private readonly apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
  ) {}

  getDemoMeals(): Observable<MealItem[]> {
    return this.http.get(this.apiUrl + '/custom/demo-meals').pipe(
      map((items: any) => {
        return items.map((item: any) => this.mapToMeal(item));
      })
    );
  }

  getMeals(date: Date): Observable<MealItem[]> {
    const requestDate = this.getUtcDate(date).toISOString();

    return this.http.get(this.apiUrl + '/custom/meals?date=' + requestDate).pipe(
      map((items: any) => {
        return items.map((item: any) => this.mapToMeal(item));
      })
    );
  }

  private getUtcDate(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  }

  private mapToMeal(item: any): MealItem {
    return {
      code: item.code,
      type: item.type,
      name: item.name,
      fabricName: item.fabricName,
      price: item.price,
      foodCost: item.foodCost,
      weight: item.weight,
      protein: item.protein,
      fat: item.fat,
      carbs: item.carbs,
      calories: item.calories,
      veg: item.veg,
      premium: item.premium,
      dcCode: item.dcCode,
      containerType: item.containerType,
      bigCalories: item.bigCalories,
      ingredients: item.ingredients,
    };
  }

}
