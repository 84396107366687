<div class="dish-modal">
  <img class="logo" src="/assets/images/logo_justfood.svg"/>
  <img class="close-btn" src="/assets/images/cancel_outline.svg" (click)="closeModalWidow()"/>

  <div class="general-info">
    <div class="dish-title">{{ meal.name }}</div>
  </div>

  <div class="details">
    <div class="parameter">
      <div class="name">Вес</div>
      <div class="value">{{ meal.weight | customNumber: 'weight' }} г</div>
    </div>

    <div class="parameter">
      <div class="name">Калорийность</div>
      <div class="value">{{ meal.calories | customNumber: 'calories' }} ккал</div>
    </div>

    <div class="parameter">
      <div class="name">Белки</div>
      <div class="value">{{ meal.protein | customNumber: 'pfc' }} г</div>
    </div>

    <div class="parameter">
      <div class="name">Жиры</div>
      <div class="value">{{ meal.fat | customNumber: 'pfc' }} г</div>
    </div>

    <div class="parameter">
      <div class="name">Углеводы</div>
      <div class="value">{{ meal.carbs | customNumber: 'pfc' }} г</div>
    </div>
  </div>

  <picture class="pic">
    <div class="type-con" *ngIf="meal.veg || meal.premium">
      <div *ngIf="meal.veg" class="type-item">
        <i class="icon-type type-veg"></i>
        <div class="tooltip">Вегетарианское блюдо</div>
      </div>
      <div *ngIf="meal.premium" class="type-item">
        <i class="icon-type type-premium"></i>
        <div class="tooltip">Премиальное блюдо</div>
      </div>
    </div>

    <div *ngIf="meal?.bigCalories" class="big-calories">
      <span>большая порция</span>
    </div>

    <img class="img"
      [lazyLoad]="meal.code | dishImage: ImageTypeEnum.sitePreview :  ImageFileTypeEnum.webp : true"
      defaultImage="assets/images/preloader.gif"
      errorImage="assets/images/meals/empty-dish.png"
      [alt]="meal.name"
      (load)="isImageLoaded = true">

    <div class="loader"></div>
  </picture>

  <div class="ingridients">
    <p class="heading">Состав</p>
    <div class="ingridients-text">
      {{ meal.ingredients || '-' }}
    </div>
  </div>

  <button class="btn--black order-btn" (click)="buy()">
    {{ meal.price + ' ₽' }} <i class="icon-plus"></i>
  </button>
</div>
