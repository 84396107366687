import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { LocalStorageKeysEnum } from '@shared/enums';
import { environment } from 'src/environments/environment';
import { RefreshTokenResp } from '../models';
import { CookieKeysEnum, CookieHeadersEnum } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.apiUrl;

  constructor(
    private jwtHelper: JwtHelperService,
    private cookieService: CookieService,
    private http: HttpClient,
    private router: Router
  ) { }

  public authUser(phone: string, token: string, refreshToken: string): void {
    this.cookieService.set(CookieKeysEnum.token, token);
    this.cookieService.set(CookieKeysEnum.refreshToken, refreshToken);

    localStorage.setItem(LocalStorageKeysEnum.phone, phone);
  }

  public logout(): void {
    sessionStorage.clear();
    localStorage.clear();

    this.cookieService.delete(CookieKeysEnum.token);
    this.cookieService.delete(CookieKeysEnum.refreshToken);

    this.router.navigate(['login']);
  }

  public invalidateCookie() {
    this.cookieService.delete(CookieKeysEnum.token);
  }

  public getAuthorizationHeader(): string {
    return `Bearer ${this.cookieService.get(CookieKeysEnum.token)}`;
  }

  public isAuthenticated(): boolean {
    const token = this.cookieService.get(CookieKeysEnum.token);

    if (!token) {
      return false;
    }

    const tokenPhone = JSON.parse(atob(token.split('.')[1]))['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
      .replace(/[^0-9]/g, '');
    const phone = localStorage.getItem(LocalStorageKeysEnum.phone);

    return tokenPhone === phone;
  }

  public refreshToken(appKey: string): Observable<RefreshTokenResp> {
    const options = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        appKey,
        authorization: `Bearer ${this.cookieService.get(CookieKeysEnum.refreshToken)}`,
        [CookieHeadersEnum.shouldSkipInterceptor]: 'true'
      }
    };

    return this.http.post<RefreshTokenResp>(this.apiUrl + '/refresh', null, options)
      .pipe(
        tap((resp) => {
          this.cookieService.set(CookieKeysEnum.token, resp.accessToken);
        })
      );
  }

  public getCode(phone: string) {
    return this.http.post(this.apiUrl + '/password', { phone });
  }

  public submitCode(phone: string, smsCode: string) {
    return this.http.post(this.apiUrl + '/v2/auth', { phone, smsCode });
  }

}
