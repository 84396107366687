import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { AuthService } from '../services';
import { CookieHeadersEnum } from '../enums';

@Injectable()
export class AllRequestsInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isDaDataRequest(req.url)) {
      return next.handle(this.getDaDaRequest(req));
    }

    if (!this.isApiRequest(req.url)) {
      return next.handle(req);
    }

    if (req.headers.has(CookieHeadersEnum.shouldSkipInterceptor)) {
      const headers = req.headers.delete(CookieHeadersEnum.shouldSkipInterceptor);
      return next.handle(req.clone({ headers }));
    }

    const request = this.getRequestWithOptions(req);

    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleAuthorizationError(error, next, request)),
      );
  }

  private handleAuthorizationError(error: HttpErrorResponse, next: HttpHandler, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    if (error.status !== 401) {
      return throwError(error);
    }

    return this.authService.refreshToken(environment.apiKey)
      .pipe(
        mergeMap(() => {
          const refreshedRequest = this.getRequestWithOptions(request);
          return next.handle(refreshedRequest);
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            this.authService.logout();
          }

          return EMPTY;
        })
      );
  }

  private getRequestWithOptions(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      withCredentials: true,
      setHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
        appKey: environment.apiKey,
        authorization: this.authService.isAuthenticated() ? this.authService.getAuthorizationHeader() : ''
      }
    });
  }

  private isDaDataRequest(url: string): boolean {
    return url.includes(environment.daDataUrl);
  }

  private getDaDaRequest(req) {
    const token = environment.daDataToken;

    return req.clone({
      headers: req.headers
        .set('Authorization', 'Token ' + token)
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json')
    });
  }

  private isApiRequest(url: string): boolean {
    return url.includes(environment.apiUrl) || url.includes('localhost');
  }
}
