import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { throwError, Subject, BehaviorSubject, forkJoin, Observable } from 'rxjs';

import { ProfileApiService, DeliveryApiService, RudderStackService } from '@shared/services';
import { DeliveryDate, AddressData, Order } from '@shared/models';
import { CommonEventsEnum } from '@shared/enums';

declare let dataLayer: any;

@Component({
  selector: 'app-date-list',
  templateUrl: './date-list.component.html',
  styleUrls: ['./date-list.component.css']
})
export class DateListComponent implements OnInit, OnDestroy {

  @Input() order: Order;
  @Output() orderChanged: EventEmitter<Order> = new EventEmitter();
  @Output() datesSelected: EventEmitter<Order> = new EventEmitter();

  public allDates: DeliveryDate[];
  public initialSelectedDates: DeliveryDate[];
  public profileError: string;
  public isLoading$ = new BehaviorSubject(false);
  public name: string;
  public fullAddress: AddressData;

  public rangeTimeControl = new FormControl(null);
  public isNewUser = false;
  public dateRanges = [
    '6:00 - 8:00',
    '7:00 - 9:00',
    '8:00 - 10:00',
    '9:00 - 11:00',
  ];

  private ngUnsubscribe = new Subject();

  constructor(
    private router: Router,
    private profileApiService: ProfileApiService,
    private deliveryApiService: DeliveryApiService,
    private rudderstack: RudderStackService,
  ) {}

  ngOnInit() {
    this.name = '';
    this.fullAddress = {
      address: '',
      apartment: '',
      comment: ''
    };

    if (!this.order) {
      this.order = new Order();
    }

    if (!this.order.fromTime || !this.order.toTime) {
      this.order.fromTime = '9:00';
      this.order.toTime = '11:00';
    }

    this.rangeTimeControl.setValue('9:00 - 11:00');

    if (sessionStorage.getItem('new_user') === '1') {
      this.isNewUser = true;
    }

    this.rangeTimeControl.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((data: string) => {
        const value = data.split(' - ');

        this.order.fromTime = value[0];
        this.order.toTime = value[1];
        this.orderChanged.emit(this.order);
      });

    this.loadData();

    if (dataLayer && dataLayer.push) {
      dataLayer.push({
        event: 'vp',
        vpURL: '/choiceofdays'
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private loadData(): void {
    this.isLoading$.next(true);

    forkJoin([
      this.getProfileData(),
      this.getDeliveryDates(),
    ])
      .pipe(
        finalize(() => this.isLoading$.next(false)),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe();
  }

  getProfileData(): Observable<unknown> {
    this.profileError = '';

    return this.profileApiService.getProfile()
      .pipe(
        tap((profile) => {
          this.onProfileLoaded(profile);
        }),
        catchError((error) => this.onProfileError(error)),
        takeUntil(this.ngUnsubscribe),
      );
  }

  async onProfileLoaded(profile): Promise<unknown> {
    if (profile?.name && profile?.address) {
      this.order.profile = profile;
      this.name = profile.name;
      this.fullAddress = {
        address: profile.address,
        apartment: profile.apartment,
        comment: profile.deliveryComment
      };
      return;
    }

    this.router.navigate(['/welcome']);
  }

  onProfileError(error) {
    this.profileError = 'Не удалось загрузить профиль. Пожалуйста, перезагрузите страницу';

    return throwError(error);
  }

  public getDeliveryDates(): Observable<unknown> {
    return this.deliveryApiService.getDeliveryDates()
      .pipe(
        tap((dates) => {
          const onlyDates = dates.filter(item => !item.hasOrder).map(item => item.date.getTime());
          if (this.order.deliveryDates && this.order.deliveryDates.filter(x => onlyDates.indexOf(x.date.getTime()) < 0).length > 0) {
            this.order.deliveryDates = [];
          }

          this.initialSelectedDates = this.order.deliveryDates;
          this.allDates = dates;
        }),
        catchError((error) => throwError(error)),
        takeUntil(this.ngUnsubscribe),
      );
  }

  public onDatesChanged(dates: DeliveryDate[]): void {
    this.order.deliveryDates = dates;
    // reset cart items cause dates are changed
    this.order.clear();

    this.orderChanged.emit(this.order);
  }

  public nextButtonText(): string {
    if (this.profileError) {
      return 'Ошибка профиля';
    } if (this.order.deliveryDates.length > 0) {
      return 'Дальше';
    }
    return 'Выберите дни доставки';
  }

  public isNextDisabled(): boolean {
    return (
      this.order.deliveryDates.length === 0 ||
      !this.name ||
      !this.fullAddress ||
      !this.rangeTimeControl.value
    );
  }

  public onNextClick(): void {
    if (this.order.deliveryDates.length > 0) {
      this.rudderstack.track(CommonEventsEnum.enterDeliveryDates);
      this.datesSelected.emit(this.order);
    }
  }

}
