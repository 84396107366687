<div class="meal-list-container">
  <div class="global-preloader" [hidden]="!(isLoading$ | async)">
    <img src="/assets/images/preloader.gif"/>
  </div>
  <div class="meals-header">
    <div class="meal-header-container">
      <div class="days-container">
        <ng-template [ngIf]="!isDemo" [ngIfElse]="demoTitle">
          <div class="more-sign" [hidden]="!havePrevDays()">...</div>
          <div class="day prev-day" (click)="onDayClick(getOrderDay(-1))">{{getOrderDay(-1) | date:'EEEE'}}</div>
          <div class="day current-day selected"
               (click)="onDayClick(getOrderDay(0))">{{getOrderDay(0) | date:'EEEE'}}</div>
          <div class="day next-day" (click)="onDayClick(getOrderDay(1))">{{getOrderDay(1) | date:'EEEE'}}</div>
          <div class="more-sign" [hidden]="!haveNextDays()">...</div>
        </ng-template>
        <ng-template #demoTitle>
          <div class="demo-title">Меню</div>
        </ng-template>
      </div>
      <div class="meal-types-container">
        <div class="meal-types">
          <div #types
            *ngFor="let type of mealTypes;"
            [appColorMealType]="type"
            class="meal-type" [attr.data-type]="type"
            [class.selected]="selectedType === type"
            (click)="onTypeClick(type)">
            {{type}}
          </div>
        </div>
        <div class="meal-filter-btns">
          <a class="meal-filter-btn veg-btn" (click)="onVegClick()" [class.active]="isVeg" title="Вегетарианское блюдо">
            <img src="/assets/images/veg.svg" />
          </a>
          <a class="meal-filter-btn premium-btn" (click)="onPremiumClick()" [class.active]="isPremium"
             title="Премиальное блюдо">
            <img src="/assets/images/premium.svg" />
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="meals-container" [ngClass]="isDemo ? 'demo' : ''">

    <div
      *ngIf="!(isLoading$ | async) && visibleMeals.length === 0"
      class="no-meals-msg">
      Нет блюд
    </div>

    <app-dish-item
      *ngFor="let meal of visibleMeals"
      class="meal dish-item"
      [attr.data-type]="meal.type"
      [meal]="meal"
      [isDemo]="isDemo"
      [itemsInCart]="itemsInCart(meal)"
      (priceClick)="onPriceClick()"
      (addItem)="onAddItemClick(meal)"
      (removeItem)="onRemoveItemClick(meal)"></app-dish-item>
  </div>

  <div class="footer meals-footer" *ngIf="isDemo">
    <a [routerLink]="['/login']" class="btn">Хочу заказать!</a>
  </div>

  <div class="footer-order" *ngIf="!isDemo">
    <div class="footer-container">
      <div class="footer-text" *ngIf="footerText()" [innerHTML]="footerText()"></div>

      <button type="button" class="btn next-btn"
        (click)="onNextClick()"
        [disabled]="isNextDisabled()">
        <div class="next-btn-text" [innerHTML]="nextButtonText()"></div>
        <div *ngIf="order.deliveryDates.length > 1" class="next-btn-subtext" [innerHTML]="nextButtonSubtext()"></div>
      </button>
    </div>

  </div>
</div>
