import { Component, OnInit, OnDestroy, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { takeUntil, catchError } from 'rxjs/operators';
import { throwError, Subject } from 'rxjs';

import { environment } from 'src/environments/environment';
import { DeliveryApiService } from '@shared/services';
import { AddressData, AddressChangedEvent } from '@shared/models';
import { isInZone } from '@shared/utils';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.css']
})
export class AddressFormComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() public showHint: boolean;
  @Input() public address: AddressData;
  @Output() public addressChanged: EventEmitter<AddressChangedEvent> = new EventEmitter();

  public form = new FormGroup({
    address: new FormControl('', [Validators.required]),
    apartment: new FormControl('', [Validators.required]),
    comment: new FormControl('')
  });

  public isHouseSelected: boolean;
  public isAddressSelected: boolean;
  public lat: number;
  public lng: number;
  public coordinates = null;
  public isMapVisible: boolean;

  private coordinatesFree: any[];
  private coordinatesPaid: any[];
  private isInPolygon: boolean;
  private isInFreeZone: boolean;
  private isInPaidZone: boolean;
  private ngUnsubscribe = new Subject();

  constructor(
    private deliveryApiService: DeliveryApiService,
  ) {}

  ngOnInit(): void {
    this.isInFreeZone = false;
    this.isInPaidZone = false;

    this.isAddressSelected = false;
    this.isHouseSelected = false;

    this.isMapVisible = false;

    this.initAddress(this.address);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private initAddress(address: AddressData): void {
    if (address && address.address) {
      this.isInPolygon = true;
      this.isAddressSelected = true;
      this.isHouseSelected = true;

      this.deliveryApiService.getAddressFromName(address.address, 1)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          catchError((error) => throwError(error))
        )
        .subscribe((data: any) => {
          if (data && data.suggestions && data.suggestions.length > 0) {
            this.lat = parseFloat(data.suggestions[0].data.geo_lat);
            this.lng = parseFloat(data.suggestions[0].data.geo_lon);
          } else {
            console.log(data);
            console.error('Suggestions are empty');
          }
        });

      this.form.setValue({
        address: address.address,
        apartment: address.apartment,
        comment: address.comment
      });
    }
  }

  public onToggleMapClick(): void {
    this.isMapVisible = !this.isMapVisible;
  }

  public onMapPointSelected(address: any): void {
    this.form.patchValue({ address: address.name });
    this.isMapVisible = false;

    this.fixData();
  }

  ngAfterViewInit(): void {
    this.deliveryApiService.getDeliveryCoords()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        catchError((error) => throwError(error))
      )
      .subscribe(([free, paid]) => {
        this.coordinatesFree = free;
        this.coordinatesPaid = paid;

        ($('#address') as any).suggestions({
          token: environment.daDataToken,
          type: 'ADDRESS',
          constraints: [
            {
              label: '',
              locations: {
                kladr_id: '7700000000000'
              }
            },
            {
              label: '',
              locations: {
                kladr_id: '50'
              },
            }
          ],
          bounds: 'city-house',
          restrict_value: true,
          onSelect: (suggestion) => {
            const suggestionData = suggestion?.data;
            const shouldDeleteCoordinates = suggestionData.qc_geo !== '0' || (suggestionData.qc !== null && suggestionData.qc !== '0');

            this.coordinates = shouldDeleteCoordinates || !suggestionData?.geo_lat
              ? null
              : {
                lat: parseFloat(suggestionData?.geo_lat),
                lon: parseFloat(suggestionData?.geo_lon)
              };

            this.isAddressSelected = true;
            this.isHouseSelected = suggestion.data.house != null;

            this.lat = suggestionData?.geo_lat;
            this.lng = suggestionData?.geo_lon;
            this.isInFreeZone = isInZone([this.lat, this.lng], this.coordinatesFree);
            this.isInPaidZone = !this.isInFreeZone && isInZone([this.lat, this.lng], this.coordinatesPaid);
            this.isInPolygon = this.isInFreeZone;
            // TODO: Выключили зону платной доставки. В дальнейшем пересмотреть такое решение!
            // this.isInPolygon = this.isInFreeZone || this.isInPaidZone;

            this.form.patchValue({ address: suggestion.value });

            this.onAddressChanged();
          },
          onInvalidateSelection: () => {
            this.invalidateAddress();

            this.onAddressChanged();
          },
          minChars: 3,
          addon: 'spinner'
        });
      });
  }

  public invalidateAddress(): void {
    this.isAddressSelected = false;
    this.isInPolygon = false;
    this.isInFreeZone = false;
    this.isInPaidZone = false;
  }

  public onAddressInput(event): void {
    if (event.target.name === 'address') {
      this.invalidateAddress();
    }

    this.onAddressChanged();
  }

  public fixData(): void {
    ($('#address') as any).suggestions().fixData();
  }

  public isAddressValid(): boolean {
    return (
      this.isAddressSelected &&
      this.isInPolygon &&
      this.form.controls.address.value &&
      this.form.controls.apartment.value
    );
  }

  public onAddressChanged(): void {
    const address: AddressData = {
      address: this.form.controls.address.value,
      apartment: this.form.controls.apartment.value,
      comment: this.form.controls.comment.value
    };

    const addressEventData: AddressChangedEvent = {
      address,
      isInPolygon: this.isInPolygon,
      isSelected: this.isAddressSelected,
      isHouseSelected: this.isHouseSelected,
      coordinates: this.coordinates,
    };

    this.addressChanged.emit(addressEventData);
  }
}
