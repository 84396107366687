/* eslint-disable max-classes-per-file */
import { ProfileData } from '@shared/models';

enum ActionTypes {
  SET_PROFILE = '[Profile] Set profile',
  PATCH_PROFILE = '[Profile] Patch profile',
  SET_PHONE = '[Profile] Set phone',
}

export class SetProfile {
  static readonly type = ActionTypes.SET_PROFILE;
  constructor(public profile: ProfileData) { }
}

export class PatchProfile {
  static readonly type = ActionTypes.PATCH_PROFILE;
  constructor(public profile: Partial<ProfileData>) { }
}

export class SetPhone {
  static readonly type = ActionTypes.SET_PHONE;
  constructor(public phone: string) { }
}
