import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { takeUntil, catchError, finalize } from 'rxjs/operators';
import { throwError, Subject, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { OrderApiService, ProfileApiService } from '@shared/services';
import { ProfileData, AddressData, AddressChangedEvent } from '@shared/models';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit, OnDestroy {

  public profile: ProfileData;
  public address: AddressData;
  public isInPolygon: boolean;
  public isAddressSelected: boolean;
  public isHouseSelected: boolean;
  public addressError: string;
  public coordinates = null;

  public isLoading$ = new BehaviorSubject(false);
  public isSaving$ = new BehaviorSubject(false);

  private ngUnsubscribe = new Subject();

  constructor(
    private titleService: Title,
    private router: Router,
    private orderApiService: OrderApiService,
    private profileApiService: ProfileApiService,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Адрес');

    this.isLoading$.next(true);
    this.profileApiService.getProfile()
      .pipe(
        finalize(() => this.isLoading$.next(false)),
        catchError((error) => this.onAddressError(error.error.error ? error.error.error : 'Неизвестная ошибка')),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((profile) => {
        if (profile?.address) {
          this.profile = profile;
          this.address = {
            address: profile.address,
            apartment: profile.apartment,
            comment: profile.deliveryComment
          };
        } else {
          this.router.navigate(['/welcome']);
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onAddressError(error) {
    this.addressError = error;

    return throwError(error);
  }

  public onBack(): void {
    if (!this.isSaving$.getValue()) {
      this.router.navigate(['/']);
    }
  }

  public onAddressChanged(address: AddressChangedEvent): void {
    this.address = address.address;
    this.isInPolygon = address.isInPolygon;
    this.isAddressSelected = address.isSelected;
    this.isHouseSelected = address.isHouseSelected;
    this.coordinates = address?.coordinates || null;
  }

  public isAddressValid(): boolean {
    return !!(
      this.isAddressSelected &&
      this.isHouseSelected &&
      this.isInPolygon &&
      this.address &&
      this.address.address &&
      this.address.apartment
    );
  }

  public saveBtnText(): string {
    if (!this.isAddressSelected) {
      return 'Выберите адрес из списка';
    }

    if (!this.isHouseSelected) {
      return 'Введите данные о доме';
    }

    if (!this.isInPolygon) {
      return 'Адрес не входит в зону доставки';
    }

    if (!this.address.apartment) {
      return 'Введите данные о квартире';
    }

    return 'Сохранить';
  }

  public onAddressSubmit(): void {
    if (!this.isAddressValid()) {
      return;
    }

    this.addressError = '';
    this.profile.address = this.address.address;
    this.profile.apartment = this.address.apartment;
    this.profile.deliveryComment = this.address.comment;
    this.profile.coordinates = this.coordinates;
    this.profile.approvedCoordinates = !!this.coordinates;

    this.isSaving$.next(true);
    this.profileApiService.saveProfile(this.profile)
      .pipe(
        finalize(() => this.isSaving$.next(false)),
        catchError((error) => this.onAddressError(error.error.error ? error.error.error : 'Неизвестная ошибка')),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        const order = this.orderApiService.loadOrder();

        order.profile = this.profile;

        this.orderApiService.saveOrder(order);

        this.router.navigate(['/']);
      });
  }

}
