import { DELIVERY_PRICE, MIN_ORDER_FREE_DELIVERY } from '@shared/configs';
import { CartItem } from './cart-item.model';
import { MealItem } from './meal-item.model';

export class Cart {
  private items: CartItem[] = [];

  copyFrom(cart: Cart) {
    this.items = cart.items;
  }

  addItem(meal: MealItem) {
    const mealIndex = this.items.map(item => item.meal.code).indexOf(meal.code);

    if (mealIndex < 0) {
      this.items.push({ meal, count: 1 });
    } else {
      // eslint-disable-next-line no-empty
      if (this.items[mealIndex].count === 99) {

      } else {
        this.items[mealIndex].count++;
      }
    }
  }

  removeItem(meal: MealItem) {
    const mealIndex = this.items.map(item => item.meal.code).indexOf(meal.code);

    // eslint-disable-next-line no-empty
    if (mealIndex < 0) {

    } else {
      if (this.items[mealIndex].count > 1) {
        this.items[mealIndex].count--;
      } else {
        this.items.splice(mealIndex, 1);
      }
    }
  }

  getItemCount(meal: MealItem): number {
    const mealIndex = this.items.map(item => item.meal.code).indexOf(meal.code);

    return mealIndex < 0 ? 0 : this.items[mealIndex].count;
  }

  getItemsCount(): number {
    return this.items.map(item => item.count).reduce((sum, count) => sum + count, 0);
  }

  getCcalCount(): number {
    return this.items.map(item => item.count * item.meal.calories).reduce((sum, count) => sum + count, 0);
  }

  getProteinsCount(): number {
    return this.items.map(item => item.count * item.meal.protein).reduce((sum, count) => sum + count, 0);
  }

  getFatsCount(): number {
    return this.items.map(item => item.count * item.meal.fat).reduce((sum, count) => sum + count, 0);
  }

  getCarbsCount(): number {
    return this.items.map(item => item.count * item.meal.carbs).reduce((sum, count) => sum + count, 0);
  }

  getSubtotal(): number {
    return this.items.map(item => item.count * item.meal.price).reduce((sum, mealsPrice) => sum + mealsPrice, 0);
  }

  getDayDeliveryPrice(): number {
    return this.getSubtotal() >= MIN_ORDER_FREE_DELIVERY
      ? 0
      : DELIVERY_PRICE;
  }

  getSubTotalWithDelivery(): number {
    return this.getSubtotal() + this.getDayDeliveryPrice();
  }

  getCarbs(): number {
    return this.items.map(item => item.count * item.meal.carbs).reduce((sum, count) => sum + count, 0);
  }

  getFat(): number {
    return this.items.map(item => item.count * item.meal.fat).reduce((sum, count) => sum + count, 0);
  }

  getProtein(): number {
    return this.items.map(item => item.count * item.meal.protein).reduce((sum, count) => sum + count, 0);
  }

  clear() {
    this.items = [];
  }

  getItems() {
    return this.items;
  }
}
