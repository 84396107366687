import { Injectable, OnDestroy, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

import {

} from '@shared/services';

@Injectable()
export class BrowserLoadingScenarioService implements OnDestroy {

  private ngOnDestroy$ = new Subject();

  constructor(
    @Inject(DOCUMENT) private dom: Document,
  ) {}

  ngOnDestroy() {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  public init(): Promise<unknown> {
    return this.addTrackerScript();
  }

  private addTrackerScript(): Promise<unknown> {
    return new Promise((resolve) => {
      const script = this.dom.createElement('script');
      script.type = 'text/javascript';

      const scriptPath = `/pageloaded/script?appKey=${environment.apiKey}`;

      script.onload = () => {
        resolve(true);
      };
      script.src = environment.apiCrm + scriptPath;
      script.async = true;

      this.dom.body.appendChild(script);
    });
  }

}
