<div class="date-list-container">
  <div class="date-list-content" >
    <div class="form-title" [ngClass]="isNewUser ? 'form-title-gray' : ''">
      Привет{{ name && !profileError ? ', ' + name : '' }}
    </div>
    <div class="form-title form-title-gray">Куда везем?</div>
    <div class="form-group">
      <div class="address">
        <div *ngIf="fullAddress.address">
          <div class="address-row">
            {{ fullAddress.address }}, {{ fullAddress.apartment }}
            <a [routerLink]="['/address']" class="address-edit-btn" title="Изменить">
              <img src="/assets/images/edit-btn.svg" />
            </a>
          </div>
        </div>
        <div *ngIf="!fullAddress.address && !profileError">...</div>
        <div *ngIf="profileError" class="profile-error">{{ profileError }}</div>
      </div>
    </div>

    <div class="form-title" [ngClass]="!isNewUser ? 'form-title-gray' : ''">Когда везем?</div>
    <div class="form-group">
      <div class="global-preloader" [hidden]="!(isLoading$ | async)">
        <img src="/assets/images/preloader.gif" />
      </div>
      <div *ngIf="(!allDates || allDates.length === 0) && !(isLoading$ | async)">Нет доступных дат</div>
      <app-day-selector *ngIf="allDates" [deliveryDates]="allDates" [selectedDates]="initialSelectedDates" (selectedDatesChanged)="onDatesChanged($event)"></app-day-selector>
    </div>

    <div class="form-title" [ngClass]="!isNewUser ? 'form-title-gray' : ''">Во сколько везем?</div>
    <div class="form-group">
      <div class="select-control">
        <select class="time-select full-width" [formControl]="rangeTimeControl">
          <option *ngFor="let item of dateRanges"
                  [ngValue]="item">{{item}}</option>
        </select>
      </div>
    </div>

    <div class="form-group next-btn-group">
        <button type="button"
          class="btn"
          (click)="onNextClick()"
          [disabled]="isNextDisabled() || (isLoading$ | async)">{{nextButtonText()}}</button>
    </div>
  </div>
</div>
