import { OnboardingComponent } from './onboarding';
import { LoginComponent } from './login';
import { WelcomeComponent } from './welcome';
import { CabinetComponent } from './cabinet';
import { ProfileComponent } from './profile';
import { AddressComponent } from './address';
import { RulesComponent } from './rules';
import { PaymentComponent } from './payment';
import { MenuComponent } from './menu/';

export const pages = [
  OnboardingComponent,
  LoginComponent,
  WelcomeComponent,
  CabinetComponent,
  ProfileComponent,
  AddressComponent,
  RulesComponent,
  PaymentComponent,
  MenuComponent,
];

export {
  OnboardingComponent,
  LoginComponent,
  WelcomeComponent,
  CabinetComponent,
  ProfileComponent,
  AddressComponent,
  RulesComponent,
  PaymentComponent,
  MenuComponent
};
