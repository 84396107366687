<div class="day-selector-container">
  <div *ngFor="let date of currentWeekDates;" class="date" [ngClass]="{ 'has-order': date.hasOrder }" [class.selected]="isSelected(date)" (click)="onSelect(date)">
    {{date.date | date:'EEEE (dd.MM.yy)'}}
    <span *ngIf="date.hasOrder" class="msg has-order-msg">Заказ уже есть</span>
    <span *ngIf="isAlmostLate(date)" class="msg late-msg">Успейте до 11:00</span>
  </div>
  <div *ngIf="nextWeekDates.length !== 0" class="next-week-label">На следующей неделе</div>
  <div *ngFor="let date of nextWeekDates;" class="date" [ngClass]="{ 'has-order': date.hasOrder }" [class.selected]="isSelected(date)" (click)="onSelect(date)">
    {{date.date | date:'EEEE (dd.MM.yy)'}}
    <span *ngIf="date.hasOrder" class="msg has-order-msg">Заказ уже есть</span>
    <span *ngIf="isAlmostLate(date)" class="msg late-msg">Успейте до 11:00</span>
  </div>
  <div *ngIf="nextWeekDates.length === 0" class="next-week-hint">Меню на следующую неделю появится в четверг.</div>
</div>