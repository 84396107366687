import { OrderItem } from './order-item.model';
import { Cart } from './cart';
import { DeliveryDate } from './delivery-date.model';
import { ProfileData } from './profile-data.model';

export class Order {
  profile: ProfileData;

  paidZoneDeliveryPrice = 250;

  deliveryDates: DeliveryDate[] = [];

  fromTime = '';
  toTime = '';

  private items: OrderItem[] = [];

  useDevices = false;

  created = false;

  promocode = '';
  discount = 0;

  copyFrom(order: Order) {
    this.profile = order.profile;
    this.deliveryDates = order.deliveryDates;
    this.fromTime = order.fromTime;
    this.toTime = order.toTime;
    this.useDevices = order.useDevices;
    this.created = order.created;
    this.promocode = order.promocode;
    this.discount = order.discount;

    for (const deliveryDate of order.deliveryDates) {
      deliveryDate.date = new Date(deliveryDate.date);
    }

    for (const orderItem of order.items) {
      const cart = new Cart();
      cart.copyFrom(orderItem.cart);
      this.addDay(new Date(orderItem.day), cart);
    }
  }

  addDay(day: Date, cart: Cart) {
    const cartIndex = this.items.map(item => item.day.getTime()).indexOf(day.getTime());

    if (cartIndex < 0) {
      this.items.push({ day, cart, isActive: false });
    } else {
      this.items[cartIndex].cart = cart;
    }
  }

  getDay(day: Date): Cart {
    const cartIndex = this.items.map(item => item.day.getTime()).indexOf(day.getTime());

    if (cartIndex < 0) {
      return new Cart();
    }
    return this.items[cartIndex].cart;
  }

  getCcalCount(): number {
    return this.items.map(item => item.cart.getCcalCount()).reduce((sum, count) => sum + count, 0);
  }

  getDeliverySummary(): number {
    return this.getMealsPriceWithDelivery() - this.getMealsPrice();
  }

  getMealsPrice(): number {
    return this.items.map(item => item.cart.getSubtotal()).reduce((sum, mealsPrice) => sum + mealsPrice, 0);
  }

  getMealsPriceWithDelivery(): number {
    return this.items.map(item => item.cart.getSubTotalWithDelivery()).reduce((sum, mealsPrice) => sum + mealsPrice, 0);
  }

  getFullPrice(): number {
    return this.getMealsPriceWithDelivery();
  }

  getFullPriceWithDiscount(): number {
    return this.getMealsPriceWithDelivery() - this.discount;
  }

  getMealsList() {
    const meals = [];

    for (const cartItem of this.items) {
      for (const mealItem of cartItem.cart.getItems()) {
        const mealIndex = meals.findIndex(x => x.name === mealItem.meal.name);
        if (mealIndex >= 0) {
          meals[mealIndex].quantity += mealItem.count;
        } else {
          meals.push({
            name: mealItem.meal.name,
            price: mealItem.meal.price,
            foodCost: mealItem.meal.foodCost,
            category: mealItem.meal.type,
            quantity: mealItem.count
          });
        }
      }
    }

    return meals;
  }

  clear() {
    this.items = [];
  }

  getItems() {
    return this.items;
  }
}
