import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ImageFileTypeEnum, ImageTypeEnum } from '@shared/enums';
import { MealItem } from '@shared/models';

@Component({
  selector: 'app-dish-modal',
  templateUrl: './dish-modal.component.html',
  styleUrls: ['./dish-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DishModalComponent implements OnInit {

  public meal: MealItem;
  public calories: string;

  public readonly ImageTypeEnum = ImageTypeEnum;
  public readonly ImageFileTypeEnum = ImageFileTypeEnum;

  public isImageLoaded = false;
  public hasSlideChanged = false;

  @ViewChild('thumbnails') thumbnailsNode: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DishModalComponent>,
  ) { }

  ngOnInit(): void {
    this.meal = this.data.meal;
    this.calories = this.data.calories || null;
  }

  public closeModalWidow(adding = false): void {
    this.dialogRef.close(adding);
  }

  public buy(): void {
    this.closeModalWidow(true);
  }

}
