import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { throwError, Subject, BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { OrderApiService, RudderStackService } from '@shared/services';
import { Order } from '@shared/models';
import { CommonEventsEnum } from '@shared/enums';
import { DELIVERY_PRICE, MIN_ORDER_FREE_DELIVERY } from "@shared/configs";

declare let dataLayer: any;

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent implements OnInit, OnDestroy {

  @Input() order: Order;
  @Output() orderChanged: EventEmitter<Order> = new EventEmitter();
  @Output() orderCreated: EventEmitter<any> = new EventEmitter();

  public promocodeControl = new FormControl('');
  public promocodeHint: string;
  public isSaving$ = new BehaviorSubject(false);
  public isCheckingPromocode = false;
  public orderError: string;

  public ngUnsubscribe = new Subject();

  constructor(
    private toastr: ToastrService,
    private orderApiService: OrderApiService,
    private rudderstack: RudderStackService,
  ) {}

  ngOnInit(): void {
    this.resetPromocode();

    if (dataLayer && dataLayer.push) {
      dataLayer.push({
        event: 'vp',
        vpURL: '/cart'
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public getDeliveryPrice(orderDay): number {
    return orderDay.cart.getSubtotal() >= MIN_ORDER_FREE_DELIVERY
      ? 0
      : DELIVERY_PRICE;
  }

  public getDeliveryPriceTooltip(orderDay): string {
    const leftPrice = MIN_ORDER_FREE_DELIVERY - orderDay.cart.getSubtotal();

    return leftPrice
      ? `До бесплатной доставки ${leftPrice}<span class="ruble">₽</span>`
      : 'Бесплатная доставка';
  }

  public onOrderError(error) {
    if (error && error.indexOf('Airtable error') === 0) {
      this.orderError = 'Во время сохранения заказа произошла ошибка.';
    } else {
      this.orderError = error;
    }

    return throwError(error);
  }

  public onTryAgainClick(): void {
    sessionStorage.clear();
    window.location.reload();
  }

  public onDevicesChecked() {
    this.order.useDevices = !this.order.useDevices;

    this.orderChanged.emit(this.order);
  }

  public onPayClicked(): void {
    this.orderError = '';

    this.rudderstack.track(CommonEventsEnum.payOrder);
    this.isSaving$.next(true);
    this.orderApiService.createOrder(this.order)
      .pipe(
        finalize(() => this.isSaving$.next(false)),
        catchError((error: any) => this.onOrderError(error.error.error ? error.error.error : 'Неизвестная ошибка')),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((createdOrderData) => {
        this.order.created = true;

        this.orderChanged.emit(this.order);
        this.orderCreated.emit(createdOrderData);
      }, (error: string) => {
        if (error) {
          this.toastr.error(error);
        }
      });
  }

  public resetPromocode(): void {
    this.order.promocode = '';
    this.order.discount = 0;
    this.promocodeHint = '';
  }

  public applyPromocodeText(): string {
    if (this.isCheckingPromocode) {
      return 'Проверяем...';
    } if (this.isPromocodeApplied()) {
      return 'Изменить';
    }
    return 'Применить';
  }

  public onApplyPromocode(): void {
    if (this.isPromocodeApplied()) {
      this.promocodeControl.setValue('');
      this.resetPromocode();

      return;
    }

    const promocode = this.promocodeControl.value;
    this.resetPromocode();

    if (!promocode) { return; }

    this.isCheckingPromocode = true;

    this.orderApiService.checkPromocode(promocode, this.order)
      .pipe(
        catchError((error: any) => this.onPromocodeError(error.error.error ? error.error.error : 'Неизвестная ошибка')),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((promocodeInfo) => {
        this.isCheckingPromocode = false;

        this.order.promocode = promocode;
        this.order.discount = promocodeInfo.discount;
        this.promocodeHint = 'Промокод применен! Скидка ' + promocodeInfo.discount + ' руб.';
      }, () => {});
  }

  public onPromocodeError(error) {
    this.isCheckingPromocode = false;
    this.promocodeHint = error;

    return throwError(error);
  }

  public isPromocodeApplied(): boolean {
    return this.order.promocode.length && this.order.discount > 0;
  }
}
