<form [formGroup]="form" class="address-form">
  <div class="form-group">
    <label for="address" class="input-hint">Улица, <span [ngClass]="isAddressSelected && !isHouseSelected ? 'no-house-text' : ''">дом</span></label>
    <div class="form-input" [ngClass]="isAddressSelected && !isHouseSelected ? 'no-house' : ''">
      <input formControlName="address" (input)="onAddressInput($event)" (blur)="fixData()" id="address" class="address-input" type="text" name="address" placeholder="Введите адрес" required />
      <button (click)="onToggleMapClick()" class="location-btn" title="Указать на карте"><img src="/assets/images/location.svg" /></button>
    </div>
  </div>

  <div class="form-group">
    <label for="apartment" class="input-hint">Квартира/офис</label>
    <input formControlName="apartment" (input)="onAddressInput($event)" id="apartment" class="apartment-input" type="text" name="apartment" placeholder="Введите номер квартиры/офиса" autocomplete="off" maxlength="30" required />
  </div>

  <div class="form-group">
    <label for="comment" class="input-hint">Комментарий</label>
    <input formControlName="comment" (input)="onAddressInput($event)" id="comment" class="comment-input" type="text" name="comment" placeholder="Например этаж, код домофона, подъезд" autocomplete="off" />
  </div>

  <div class="form-group msg" [hidden]="!showHint">
    <div class="msg-title">Важно.</div>
    <div class="msg-text">Адрес распространяется на все доставки в заказе. Если хотите доставку по разным адресам - оформите разные заказы на каждый адрес.</div>
  </div>
</form>

<app-address-map
  *ngIf="isMapVisible"
  [lat]="lat"
  [lng]="lng"
  (pointSelected)="onMapPointSelected($event)"
  (closeClicked)="onToggleMapClick()"></app-address-map>
