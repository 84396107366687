import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { MatDialogModule } from '@angular/material/dialog';

import { ComponentsModule } from './components';
import { PipesModule } from './pipes';
import { DirectivesModule } from './directive';

const modules = [
  ComponentsModule,
  PipesModule,
  DirectivesModule,
  MatDialogModule,
];

@NgModule({
  imports: [
    CommonModule,
    NgxWebstorageModule.forRoot(),
    ...modules,
  ],
  exports: [
    NgxWebstorageModule,
    ...modules,
  ],
})
export class SharedModule { }
