import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ProfileData } from '@shared/models';
import { LocalStorageKeysEnum } from '@shared/enums';

@Injectable({
  providedIn: 'root'
})
export class ProfileApiService {

  private readonly apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
  ) {}

  getPhone(): string {
    return localStorage.getItem(LocalStorageKeysEnum.phone);
  }

  isProfileFilled(): Observable<boolean> {
    return this.http.get<boolean>(this.apiUrl + '/custom/is-profile-filled');
  }

  getClientOrdersCount(): Observable<number> {
    return this.http.get<number>(this.apiUrl + '/custom/orders-count');
  }

  getClientGuid(): Observable<string> {
    return this.http.get<string>(this.apiUrl + '/custom/client-guid');
  }

  getProfile(): Observable<ProfileData> {
    return this.http.get(this.apiUrl + '/custom/client').pipe(
      map((profileData: any) => {
        if (!profileData) {
          localStorage.setItem(LocalStorageKeysEnum.userGuid, '');
          return null;
        }

        localStorage.setItem(LocalStorageKeysEnum.userGuid, profileData.crmGuid);
        return {
          id: profileData.crmGuid,
          crmGuid: profileData.crmGuid,
          googleClientId: profileData.googleClientId,
          yandexClientId: profileData.yandexClientId,
          phone: profileData.phone,
          name: profileData.name,
          address: profileData.address,
          apartment: profileData.apartment,
          deliveryComment: profileData.deliveryComment,
          approvedCoordinates: profileData?.approvedCoordinates || false
        };
      })
    );
  }

  saveProfile(profile: Partial<ProfileData>) {
    const data = {
      googleClientId: profile.googleClientId,
      yandexClientId: profile.yandexClientId,
      phone: this.getPhone(),
      name: profile.name,
      address: profile.address,
      apartment: profile.apartment,
      deliveryComment: profile.deliveryComment,
      approvedCoordinates: profile.approvedCoordinates || false,
      coordinates: profile.coordinates
    };

    return this.http.put(this.apiUrl + '/custom/client', data);
  }

}
