import { Injectable } from '@angular/core';
import {
  State,
  Action,
  StateContext,
  Store,
} from '@ngxs/store';

import { ProfileData } from '@shared/models';
import { SetPhone, SetProfile, PatchProfile } from './profile.actions';
import { ProfileStateModel } from './profile.model';

@State<ProfileStateModel>({
  name: 'profile',
  defaults: {
    profile: null,
    phone: null,
  }
})
@Injectable()
export class ProfileState {

  constructor(
    private store: Store,
  ) {}

  @Action(SetProfile)
  setProfile(ctx: StateContext<ProfileStateModel>, { profile }: SetProfile): void {
    ctx.patchState({
      profile,
    });
  }

  @Action(PatchProfile)
  patchProfile(ctx: StateContext<ProfileStateModel>, data: ProfileData): void {
    const profile = {
      ...(ctx.getState()?.profile || {}),
      ...data,
    };

    ctx.patchState({
      profile
    });
  }

  @Action(SetPhone)
  setPhone(ctx: StateContext<ProfileStateModel>, phone: string): void {
    ctx.patchState({
      phone,
    });
  }

}
