import { Pipe, PipeTransform } from '@angular/core';
import { ImageColorEnum, ImageFileTypeEnum, ImageSizeEnum, ImageTypeEnum } from '@shared/enums';

interface ImageParamsMap {
  [key: string]: {
    size: ImageSizeEnum;
    retinaSize?: ImageSizeEnum;
    backgroundColor: ImageColorEnum;
    isCropped?: boolean;
  }
}
@Pipe({
  name: 'dishImage'
})
export class DishImagePipe implements PipeTransform {

  private imageParamsMap: ImageParamsMap = {
    [ImageTypeEnum.sitePreview]: {
      size: ImageSizeEnum.medium,
      retinaSize: ImageSizeEnum.w560,
      backgroundColor: ImageColorEnum.transparent,
    },
    [ImageTypeEnum.icon]: {
      size: ImageSizeEnum.xSmall,
      backgroundColor: ImageColorEnum.transparent,
    },
    [ImageTypeEnum.oldSitePreview]: {
      size: ImageSizeEnum.medium,
      backgroundColor: ImageColorEnum.gray,
    },
    [ImageTypeEnum.newDesign]: {
      size: ImageSizeEnum.w180,
      retinaSize: ImageSizeEnum.w360,
      backgroundColor: ImageColorEnum.transparent,
      isCropped: true
    },
    [ImageTypeEnum.newDesignMobile]: {
      size: ImageSizeEnum.w200,
      retinaSize: ImageSizeEnum.w400,
      backgroundColor: ImageColorEnum.transparent,
      isCropped: true
    },
  };

  transform(
    dishCode: string,
    imageType: ImageTypeEnum = ImageTypeEnum.sitePreview,
    fileType: ImageFileTypeEnum = ImageFileTypeEnum.png,
    isRetina?: boolean
  ): string {
    if (!dishCode) { return ''; }

    if (imageType === ImageTypeEnum.oldSitePreview) {
      return `https://crm.justfood.pro/api/files/${dishCode}-c.png`;
    }

    const params = this.imageParamsMap[imageType];

    const filesPath = 'https://crm.justfood.pro/api/files/';
    const cropPostfix = params.isCropped ? '-cropped' : '';
    const size = isRetina ? params.retinaSize : params.size;

    return `${filesPath}${dishCode}-${size}-${params.backgroundColor}${cropPostfix}.${fileType}`;
  }
}
