<app-header [isOnboarding]="false" [step]="2" (back)="onBack()"></app-header>

<div class="address-container">
  <form class="address-form" (ngSubmit)="onAddressSubmit()">
    <div class="form-header">
      <div class="form-title">Куда везем?</div>
    </div>

    <div class="form-controls">
      <div class="global-preloader blocking" [hidden]="!(isLoading$ | async) && !(isSaving$ | async)">
        <img src="/assets/images/preloader.gif" />
      </div>

      <app-address-form *ngIf="!(isLoading$ | async) || address" #addressForm [address]="address" [showHint]="true" (addressChanged)="onAddressChanged($event)"></app-address-form>

      <div class="error">{{addressError}}</div>
    </div>

    <div class="footer">
      <button type="submit" class="btn" [disabled]="!isAddressValid() || (isLoading$ | async) || (isSaving$ | async)">{{saveBtnText()}}</button>
    </div>
  </form>
</div>
