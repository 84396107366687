export enum CommonEventsEnum {
  pageLoaded = 'CUSTOM_PAGE_LOADED',
  skipIntro = 'CUSTOM_SKIP_INTRO',
  getConfirmCode = 'CUSTOM_GET_CONFIRM_CODE',
  enterName = 'CUSTOM_ENTER_NAME',
  enterProfileInfo = 'CUSTOM_ENTER_PROFILE_INFO',
  enterDeliveryDates = 'CUSTOM_ENTER_DELIVERY_DATES',
  dishesSelected = 'CUSTOM_DISHES_SELECTED',
  payOrder = 'CUSTOM_PAY_ORDER',
}

export enum RudderstackStorageKeys {
  initialReferrer = 'RUDDERSTACK_INITIAL_REFERRER'
}
