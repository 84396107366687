import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';

import * as Sentry from '@sentry/angular';

import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';

import { AllRequestsInterceptor } from '@shared/interceptors';
import { environment } from 'src/environments/environment';
import { denyUrls, getEnvironment, ignoreErrors } from '@shared/configs';
import { SharedModule } from '@shared/shared.module';
import { StoreModule } from '@store/store.module';

import { AppComponent } from './app.component';
import { pages } from './pages';
import { ROUTES } from './app.routing';

registerLocaleData(localeRu, 'ru');

Sentry.init({
  dsn: environment.sentryDsn,
  ignoreErrors,
  denyUrls,
  environment: getEnvironment(),
});

@NgModule({
  declarations: [
    AppComponent,
    ...pages,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    SharedModule,
    StoreModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(ROUTES, { relativeLinkResolution: 'legacy' })
  ],
  providers: [
    CookieService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: LOCALE_ID, useValue: 'ru' },
    JwtHelperService,
    { provide: HTTP_INTERCEPTORS, useClass: AllRequestsInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
